import React from 'react'
import { NavLink } from 'react-router-dom'
import logo from 'assets/images/nav.png'
import routes from 'routes'
import { useCurrentUser } from 'Store/User'
import { useLoadReports } from 'Action/daily'
import { useLoadStudents } from 'Action/student'
import { useLogout } from 'Action/auth'
import { useLoadClasses } from 'Action/class'
import { useLoadUsers } from '../Action/admin'

interface Props {
    children: React.ReactNode
}

export default function Layout({ children }: Props) {
    const [user] = useCurrentUser()
    const loadReports = useLoadReports()
    const loadStudents = useLoadStudents()
    const loadClasses = useLoadClasses()
    const loadUsers = useLoadUsers()
    const logout = useLogout()

    const onReportClick = () => {
        loadReports(0).then()
    }
    const onStudentClick = () => {
        loadStudents(0).then()
    }
    const onClassClick = () => {
        loadClasses(0).then()
    }
    const onAdminClick = () => {
        loadUsers().then()
    }
    const onLogoutClick = () => {
        logout().then()
    }

    return (
        <div className="w-full h-full flex flex-col">
            <div className="bg-white shadow flex flex-col sm:flex-row flex-shrink-0 sm:items-center relative sm:h-20 text-gray-6e text-lg font-medium">
                <img
                    src={logo}
                    className="w-14 sm:mr-6 sm:ml-6 ml-5 mt-2 sm:mt-0"
                />
                <NavLink
                    to={routes.daily}
                    className="text-base sm:mr-5 mt-3 sm:mt-0 ml-5 sm:ml-0"
                    exact
                    activeClassName="text-blue-highlight"
                    onClick={onReportClick}
                >
                    Daily Report
                </NavLink>
                <NavLink
                    to={routes.management}
                    className="text-base sm:mr-5 mt-3 sm:mt-0 ml-5 sm:ml-0"
                    exact
                    activeClassName="text-blue-highlight"
                    onClick={onStudentClick}
                >
                    Student Management
                </NavLink>
                <NavLink
                    to={routes.class}
                    className="text-base sm:mr-5 mt-3 sm:mt-0 ml-5 sm:ml-0"
                    exact
                    activeClassName="text-blue-highlight"
                    onClick={onClassClick}
                >
                    Class
                </NavLink>
                <NavLink
                    to={routes.invoice}
                    className="text-base sm:mr-5 hidden sm:block"
                    exact
                    activeClassName="text-blue-highlight"
                >
                    Invoice
                </NavLink>
                {user && user.isAdmin && (
                    <NavLink
                        to={routes.admin}
                        className="text-base mt-3 sm:mt-0 ml-5 sm:ml-0"
                        exact
                        activeClassName="text-blue-highlight"
                        onClick={onAdminClick}
                    >
                        Admin
                    </NavLink>
                )}
                <button
                    className="sm:ml-auto sm:mr-5 text-base text-black mt-3 sm:mt-0 ml-5 sm:ml-0 mb-3 sm:mb-0"
                    onClick={onLogoutClick}
                >
                    Log out
                </button>
            </div>
            <div className="w-full sm:w-4/5 flow-1 mx-auto mt-8 flex-1 my-0.5 bg-white rounded shadow-around">
                {children}
            </div>
            <div className="h-20 flex-shrink-0 flex flex-col text-center justify-center">
                <span className="text-gray-6b text-xl">
                    Herald Language College
                </span>
            </div>
        </div>
    )
}
