import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import Input from 'Shared/Form/Input'
import { toTitleCase } from 'Util/toTitleCase'

type Props = {
    // eslint-disable-next-line
    form: UseFormReturn<any>
    name: string
}

export default function SingleLineInput({ form, name }: Props) {
    return (
        <Input
            form={form}
            name={name}
            label={toTitleCase(name)}
            classes={{
                root: 'flex flex-row h-10 rounded shadow mb-3.5',
                label: 'bg-blue-form w-24 items-center justify-center inline-flex',
                input: 'text-black placeholder-gray-400 px-2 w-full border p-2',
            }}
        />
    )
}
