import { createContext, Dispatch } from 'react'
import { replaceExistence } from 'Util/existence'

export enum ActionType {
    StudentAdded = 'studentAdded',
    StudentsLoaded = 'studentsLoaded',
    StudentLoaded = 'studentLoaded',
    StudentPagesLoaded = 'studentPagesLoaded',
    StudentsClear = 'studentsClear',
}

export type Student = {
    id: number
    name: string
    telephone: string
    agency: string
    email: string
    enrollment: 'Prospect' | 'Enrolled'
    memo: string
    date: Date
}
export const emptyStudent: Student = {
    id: 0,
    name: '',
    telephone: '',
    agency: '',
    email: '',
    enrollment: 'Prospect',
    memo: '',
    date: new Date(),
}

export type StudentState = {
    students: { [key: number]: Student[] }
    student: Student
    pageCount: number
}

export type StudentAction =
    | {
          type: ActionType.StudentPagesLoaded
          count: number
      }
    | {
          type: ActionType.StudentsLoaded
          page: number
          students: Student[]
      }
    | {
          type: ActionType.StudentLoaded
          student: Student
      }
    | {
          type: ActionType.StudentAdded
          student: Student
      }
    | {
          type: ActionType.StudentsClear
      }

export type StudentContextType = {
    studentState: StudentState
    dispatch: Dispatch<StudentAction>
}

export const initialState: StudentState = {
    students: {},
    student: emptyStudent,
    pageCount: 0,
}

export const studentReducer = (
    state: StudentState,
    action: StudentAction,
): StudentState => {
    switch (action.type) {
        case ActionType.StudentPagesLoaded:
            return {
                ...state,
                pageCount: action.count,
            }
        case ActionType.StudentsLoaded:
            // eslint-disable-next-line no-case-declarations
            const students = state.students
            students[action.page] = action.students
            return {
                ...state,
                students,
            }
        case ActionType.StudentLoaded:
            return {
                ...state,
                student: action.student,
            }
        case ActionType.StudentAdded:
            return {
                ...state,
                students: replaceExistence(state.students, action.student),
            }
        case ActionType.StudentsClear:
            return {
                ...state,
                students: {},
            }
        default:
            return initialState
    }
}

export const StudentContext = createContext<StudentContextType>(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    undefined,
)
