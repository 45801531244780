export function replaceExistence(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: { [key: number]: any[] },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    newValue: any,
) {
    if (values[0]) {
        if (values[0].length > 0 && 'student' in values[0][0]) {
            const index = values[0]
                .map((r, i) => (r.student.id === newValue.student.id ? i : -1))
                .filter((i) => i >= 0)
            if (index.length > 0) {
                values[0][index[0]] = newValue
            } else {
                values[0] = [newValue].concat(values[0])
            }
        } else {
            const index = values[0]
                .map((r, i) => (r.id === newValue.id ? i : -1))
                .filter((i) => i >= 0)
            if (index.length > 0) {
                values[0][index[0]] = newValue
            } else {
                values[0] = [newValue].concat(values[0])
            }
        }
    } else {
        values[0] = [newValue]
    }
    return values
}
