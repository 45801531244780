import { useContext } from 'react'
import { ActionType, Invoice, InvoiceContext } from 'Store/Invoice'

export function useInvoice() {
    const { invoiceState } = useContext(InvoiceContext)
    return invoiceState.invoice
}

export function useSetInvoice() {
    const { dispatch } = useContext(InvoiceContext)
    return async (invoice: Invoice) => {
        await dispatch({
            type: ActionType.InvoiceSaved,
            invoice,
        })
    }
}

export function useClearInvoice() {
    const { dispatch } = useContext(InvoiceContext)
    return async () => {
        await dispatch({
            type: ActionType.InvoiceCleared,
        })
    }
}
