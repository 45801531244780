import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import routes from 'routes'
import { emptyStudent, Student, StudentContext } from 'Store/Student'
import {
    useClearStudents,
    useLoadPageCount,
    useLoadStudents,
    useSetStudent,
} from 'Action/student'
import { useForm } from 'react-hook-form'
import { pageValues } from 'Util/page'
import cn from 'classnames'
import { ClassContext } from 'Store/Class'
import {
    useAddStudent,
    useClearNotInClassStudents,
    useLoadStudentsNotInClass,
    useLoadStudentsNotInClassCount,
} from 'Action/class'
import dayjs from 'dayjs'

type Props = {
    isClass?: boolean
    onClose?: () => void
}

export default function Management({ isClass = false, onClose }: Props) {
    const page = isClass
        ? 0
        : parseInt(
              new URLSearchParams(window.location.search).get('page') ?? '0',
          ) ?? 0
    // Bare student
    const { studentState } = useContext(StudentContext)
    const loadStudentPageCount = useLoadPageCount()
    const loadNormalStudents = useLoadStudents()
    const setStudent = useSetStudent()
    const clearNormalStudents = useClearStudents()

    // Class
    const { classState } = useContext(ClassContext)
    const loadClassPageCount = useLoadStudentsNotInClassCount()
    const loadClassStudents = useLoadStudentsNotInClass()
    const clearClassStudents = useClearNotInClassStudents()
    const addStudent = useAddStudent()

    const pageCount = isClass
        ? classState.studentsNotInClassCount
        : studentState.pageCount
    const students = isClass
        ? classState.studentsNotInClass
        : studentState.students
    const loadPageCount: () => Promise<unknown> = isClass
        ? loadClassPageCount.bind(loadClassPageCount, classState.class.id)
        : loadStudentPageCount
    const loadStudents = isClass
        ? loadClassStudents.bind(loadClassStudents, classState.class.id)
        : loadNormalStudents
    const clearStudents = isClass ? clearClassStudents : clearNormalStudents

    const [searchKey, setSearchKey] = useState<string | undefined>(undefined)
    const form = useForm({
        defaultValues: {
            searchKey: undefined,
        },
    })
    const onSearchKeyChange = async ({ searchKey }: { searchKey: string }) => {
        setSearchKey(searchKey === '' ? undefined : searchKey)
        await clearStudents()
    }
    const selectForm = useForm()
    const onStudentSelect = async ({ studentId }: { studentId: string }) => {
        const student = students[page].filter(
            (s) => s.id === parseInt(studentId),
        )[0]
        await addStudent(student)
        await onSearchKeyChange({ searchKey: '' })
        onClose && onClose()
    }

    useEffect(() => {
        if (pageCount === 0) {
            loadPageCount().then()
        }
    }, [pageCount])
    useEffect(() => {
        if (!students[page]) {
            loadStudents(page, searchKey).then()
        }
    }, [page, students[page], searchKey])
    const onStudentClick = (student?: Student) => {
        setStudent(student ?? emptyStudent).then()
    }
    const { pageStart, pageEnd, prevExits, nextExists, pageRange } = pageValues(
        page,
        pageCount,
    )

    return (
        <div className="w-full flex-1 mx-auto pt-9 px-2 sm:px-7 flex flex-col">
            <div className="flex sm:flex-row flex-col">
                <span className="font-bold text-3xl ml-1.5">
                    Student Management
                </span>
                <form
                    onSubmit={form.handleSubmit(onSearchKeyChange)}
                    className="sm:my-auto sm:ml-10 mt-5 ml-1.5"
                >
                    <input
                        {...form.register('searchKey')}
                        className="border rounded border-gray-400 px-2"
                        placeholder="student name"
                    />
                    <button
                        className="border rounded border-gray-400 bg-gray-de text-gray-500 w-16 ml-2 text-sm"
                        type="submit"
                    >
                        Search
                    </button>
                </form>
                {isClass && (
                    <div className="flex flex-col sm:flex-row mt-3 sm:mt-0 sm:ml-auto">
                        <button
                            className="text-base bg-gray-e4 h-7 w-20 text-center text-black border border-gray-97 rounded ml-1.5 my-auto mr-3"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                        <button
                            className="text-base bg-red-tint h-7 w-20 text-center text-white rounded sm:ml-auto sm:my-auto ml-1.5 mt-5"
                            onClick={selectForm.handleSubmit(onStudentSelect)}
                        >
                            Send
                        </button>
                    </div>
                )}
                {!isClass && (
                    <NavLink
                        to={routes.student(0)}
                        className="text-base bg-red-tint h-7 w-20 text-center text-white rounded sm:ml-auto sm:my-auto ml-1.5 mt-5"
                        exact
                        onClick={() => onStudentClick()}
                    >
                        Create
                    </NavLink>
                )}
            </div>
            <form>
                <table className="w-full table-fixed sm:table-auto mt-3.5 text-center border-separate">
                    <thead>
                        <tr className="bg-blue-thead">
                            <th>No.</th>
                            <th>Name</th>
                            <th className={isClass ? 'hidden' : 'sm:hidden'}>
                                Tel
                            </th>
                            <th className="hidden sm:table-cell">Telephone</th>
                            <th>Agency</th>
                            <th className="hidden md:table-cell">Email</th>
                            <th className="hidden sm:table-cell">Enrollment</th>
                            <th className="sm:hidden">Enroll</th>
                            {isClass && <th>Select</th>}
                            {!isClass && (
                                <th className="hidden sm:table-cell">Date</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {students[page] &&
                            students[page].map((student, i) => (
                                <tr
                                    key={`${i}`}
                                    className={
                                        i % 2 === 0
                                            ? 'bg-white'
                                            : 'bg-blue-tbody'
                                    }
                                >
                                    <td>{student.id}</td>
                                    <td>
                                        <NavLink
                                            to={routes.student(student.id)}
                                            className="text-blue-hyperlink break-all sm:break-words"
                                            onClick={() =>
                                                onStudentClick(student)
                                            }
                                        >
                                            {student.name}
                                        </NavLink>
                                    </td>
                                    <td
                                        className={cn(
                                            isClass && 'hidden sm:table-cell',
                                        )}
                                    >
                                        {student.telephone}
                                    </td>
                                    <td>{student.agency}</td>
                                    <td className="hidden md:block">
                                        <a
                                            href={`mailto:${student.email}`}
                                            className="text-blue-hyperlink"
                                        >
                                            {student.email}
                                        </a>
                                    </td>
                                    <td className="truncate">
                                        {student.enrollment}
                                    </td>
                                    <td
                                        className={
                                            isClass
                                                ? 'table-cell'
                                                : 'hidden sm:block'
                                        }
                                    >
                                        {isClass && (
                                            <input
                                                {...selectForm.register(
                                                    'studentId',
                                                )}
                                                value={student.id}
                                                type="radio"
                                            />
                                        )}
                                        {!isClass &&
                                            dayjs(student.date).format(
                                                'YYYY-MM-DD',
                                            )}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </form>
            {isClass && <div className="h-2 pb-4" />}
            {!isClass && (
                <div className="mx-auto my-5 flex flex-row flex-1 space-x-2">
                    {prevExits && (
                        <NavLink
                            to={`${routes.management()}?page=${pageStart - 2}`}
                            className="text-base text-black"
                        >
                            &lt;
                        </NavLink>
                    )}
                    {pageRange.map((r) => (
                        <NavLink
                            key={r}
                            to={`${routes.management()}?page=${r - 1}`}
                            className={cn(
                                'text-base',
                                r - 1 === page
                                    ? 'text-blue-primary'
                                    : 'text-black',
                            )}
                        >
                            {r}
                        </NavLink>
                    ))}
                    {nextExists && (
                        <NavLink
                            to={`${routes.management()}?page=${pageEnd}`}
                            className="text-base text-black"
                        >
                            &gt;
                        </NavLink>
                    )}
                </div>
            )}
        </div>
    )
}
