import React from 'react'
import { default as GeneralInput } from 'Shared/Form/Input'
import { UseFormReturn } from 'react-hook-form'

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form: UseFormReturn<any>
    name: string
    label?: string
    placeholder?: string
    className?: string
    type?: string
}

export default function Input({
    form,
    name,
    label,
    className,
    ...props
}: Props) {
    return (
        <GeneralInput
            form={form}
            name={name}
            label={label}
            classes={{
                root: className,
                input: 'px-4 h-10 w-full bg-gray-f5 border border-gray-8b rounded',
                error: 'text-red-500 text-center',
            }}
            {...props}
        />
    )
}
