import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from 'reportWebVitals'
import Store from 'Store'
import { default as Routes } from 'App/Router'
import { Router } from 'react-router-dom'
import history from 'Util/history'
import 'styles/output.css'

ReactDOM.render(
    <React.StrictMode>
        <Store>
            <Router history={history}>
                <Routes />
            </Router>
        </Store>
    </React.StrictMode>,
    document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
