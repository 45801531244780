import { useDeleteClass, useSetClass, useSetModifyingClass } from 'Action/class'
import React, { useContext } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { NavLink } from 'react-router-dom'
import routes from 'routes'
import { ClassContext, HeraldClass } from 'Store/Class'

type Props = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form: UseFormReturn<any>
    heraldClass: HeraldClass
    className: string
    showFormOnly: boolean
    isSmallDisplay: boolean
}

export default function Row({
    form,
    heraldClass,
    className,
    showFormOnly,
    isSmallDisplay,
}: Props) {
    const {
        classState: { modifyingClass },
    } = useContext(ClassContext)
    const setClass = useSetClass()
    const setModifyingClass = useSetModifyingClass()
    const deleteClass = useDeleteClass()

    const shouldShowForm =
        !isSmallDisplay &&
        modifyingClass &&
        modifyingClass.id === heraldClass.id

    const onCancel = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        await setModifyingClass()
    }
    const onModify = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        await setModifyingClass(heraldClass)
        form.setValue('name', heraldClass.name)
        form.setValue('date', heraldClass.date)
    }
    const onDelete = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        if (
            window.confirm(
                '한번 삭제한 자료는 복구할 방법이 없습니다.\n\n정말 삭제하시겠습니까?',
            )
        ) {
            deleteClass(heraldClass).then()
        }
    }
    const onClassSelected = async (heraldClass: HeraldClass) => {
        await setClass(heraldClass)
    }

    if (showFormOnly) {
        return (
            <div className="flex flex-col w-full py-4 bg-blue-tbody">
                <input
                    {...form.register('name')}
                    placeholder="Name"
                    className="h-10 mt-3"
                />
                <input
                    {...form.register('date')}
                    placeholder="date"
                    className="h-10 mt-3"
                />
                <button
                    className="text-base border border-gray-35 h-10 w-full text-center text-gray-35 rounded my-auto mt-3"
                    onClick={onCancel}
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="text-base bg-red-tint h-10 w-full text-center text-white rounded mt-3"
                >
                    Save
                </button>
            </div>
        )
    }

    return (
        <tr className={className}>
            <td>{heraldClass.id}</td>
            <td>
                {shouldShowForm && (
                    <input {...form.register('name')} placeholder="name" />
                )}
                {!shouldShowForm && (
                    <NavLink
                        to={routes.classDetail(heraldClass.id)}
                        className="text-blue-hyperlink"
                        onClick={() => onClassSelected(heraldClass)}
                    >
                        {heraldClass.name}
                    </NavLink>
                )}
            </td>
            <td>
                {shouldShowForm && (
                    <input {...form.register('date')} placeholder="date" />
                )}
                {!shouldShowForm && heraldClass.date}
            </td>
            <td className="text-left">
                {shouldShowForm && (
                    <div className="flex flex-col md:flex-row">
                        <button
                            className="text-base border border-gray-35 h-7 w-20 text-center text-gray-35 rounded my-auto ml-1 mt-2 md:mt-0 mb-2 md:mb-0"
                            onClick={onCancel}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="text-base bg-red-tint h-7 w-20 text-center text-white rounded ml-1 my-auto"
                        >
                            Save
                        </button>
                    </div>
                )}
                {!shouldShowForm && (
                    <div className="flex flex-col md:flex-row">
                        <button
                            className="text-base border border-gray-35 h-7 w-20 text-center text-gray-35 rounded my-auto ml-1 mt-2 md:mt-0 mb-2 md:mb-0"
                            onClick={onModify}
                        >
                            Modify
                        </button>
                        <button
                            className="text-base bg-gray-35 h-7 w-20 text-center text-white rounded my-auto ml-1 md:ml-2 mt-2 md:mt-0 mb-2 md:mb-0"
                            onClick={onDelete}
                        >
                            Delete
                        </button>
                    </div>
                )}
            </td>
        </tr>
    )
}
