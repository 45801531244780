export default {
    login: () => '/login',
    daily: () => '/daily',
    management: () => '/management',
    admin: () => '/admin',
    invoice: () => '/invoice',
    report: (id?: number) => `/daily/${typeof id === 'number' ? id : ':id'}`,
    student: (id?: number) =>
        `/management/${typeof id === 'number' ? id : ':id'}`,
    user: (id?: number) => `/admin/${typeof id === 'number' ? id : ':id'}`,
    class: () => '/class',
    classDetail: (id?: number) =>
        `/class/${typeof id === 'number' ? id : ':id'}`,
}
