import React, { useState } from 'react'
import logo from 'assets/images/logo.jpg'
import { useForm } from 'react-hook-form'
import Input from 'Auth/Input'
import Loader from 'Shared/Loader'
import { useLogin } from 'Action/auth'

export default function Auth() {
    const form = useForm()
    const [isLoading, setLoading] = useState(false)
    const login = useLogin()

    const onSubmit = async (values: { username: string; password: string }) => {
        if (!values.username || !values.password) {
            form.setError('password', { message: 'Invalid entry' })
            return
        }
        setLoading(true)
        await login({
            login: values.username,
            password: values.password,
        })
        setLoading(false)
        form.setError('password', { message: 'Invalid entry' })
    }

    return (
        <form
            className="flex-center h-full"
            onSubmit={form.handleSubmit(onSubmit)}
        >
            <div className="flex flex-col h-screen">
                <div className="w-1/3 min-w-max bg-white shadow-around text-center m-auto pt-5 pb-5 px-12">
                    <img className="w-20 h-20 block m-auto" src={logo} />
                    <Input
                        className="mt-5"
                        form={form}
                        name="username"
                        type="text"
                        placeholder="ID"
                    />
                    <Input
                        className="mt-5"
                        form={form}
                        name="password"
                        type="password"
                        placeholder="Password"
                    />
                    <button
                        className="bg-blue-primary h-10 w-full rounded mt-5 text-white"
                        disabled={isLoading}
                    >
                        {!isLoading && 'Log In'}
                        {isLoading && <Loader className="w-8 h-8 mt-1" />}
                    </button>
                </div>
            </div>
        </form>
    )
}
