import { login, LoginParam, logout } from '../Auth/api'

export function useLogin() {
    return async (data: LoginParam) => {
        try {
            await login(data)
        } catch (e) {
            return 'Error while processing request'
        }
    }
}

export function useLogout() {
    return async () => {
        try {
            await logout()
        } catch (e) {
            return 'Error while processing request'
        }
    }
}
