import assert from 'assert'

export default function range(start: number, count?: number) {
    if (arguments.length == 1) {
        count = start
        start = 0
    }
    assert(count)

    const foo = []
    for (let i = 0; i < count; i++) {
        foo.push(start + i)
    }
    return foo
}
