import React, { useContext, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import routes from 'routes'
import { DailyContext, DailyReport, emptyReport } from 'Store/Daily'
import { useLoadPageCount, useLoadReports, useSetReport } from 'Action/daily'
import cn from 'classnames'
import { pageValues } from '../Util/page'
import dayjs from 'dayjs'

export default function Daily() {
    const page =
        parseInt(
            new URLSearchParams(window.location.search).get('page') ?? '0',
        ) ?? 0
    const { dailyState } = useContext(DailyContext)
    const loadPageCount = useLoadPageCount()
    const loadReports = useLoadReports()
    const setReport = useSetReport()

    useEffect(() => {
        if (dailyState.pageCount === 0) {
            loadPageCount().then()
        }
    }, [dailyState.pageCount])
    useEffect(() => {
        if (!dailyState.reports[page]) {
            loadReports(page).then()
        }
    }, [page, dailyState.reports[page]])
    const onReportClick = (report?: DailyReport) => {
        setReport(report ?? emptyReport).then()
    }
    const { pageStart, pageEnd, prevExits, nextExists, pageRange } = pageValues(
        page,
        dailyState.pageCount,
    )

    return (
        <div className="w-full flex-1 mx-auto pt-9 px-2 sm:px-7 flex flex-col">
            <div className="flex flex-row">
                <span className="font-bold text-3xl ml-1.5">Daily Report</span>
                <NavLink
                    to={routes.report(0)}
                    className="text-base bg-red-tint h-7 w-20 text-center text-white rounded ml-auto my-auto"
                    exact
                    onClick={() => onReportClick()}
                >
                    Create
                </NavLink>
            </div>
            <table className="w-full table-fixed sm:table-auto mt-3.5 text-center border-separate">
                <thead>
                    <tr className="bg-blue-thead">
                        <th>Date</th>
                        <th>Writer</th>
                        <th>Payment</th>
                        <th>Memo</th>
                    </tr>
                </thead>
                <tbody>
                    {dailyState.reports[page] &&
                        dailyState.reports[page].map((report, i) => (
                            <tr
                                key={i}
                                className={
                                    i % 2 === 0 ? 'bg-white' : 'bg-blue-tbody'
                                }
                            >
                                <td className="px-2 break-words sm:break-normal text-gray-6b">
                                    {dayjs(report.date).format('YYYY.MM.DD')}
                                </td>
                                <td className="sm:whitespace-nowrap px-2">
                                    {report.writer}
                                </td>
                                <td className="sm:whitespace-nowrap px-2">
                                    <NavLink
                                        to={routes.report(report.id)}
                                        className="text-blue-hyperlink"
                                        onClick={() => onReportClick(report)}
                                    >
                                        {report.payment}
                                    </NavLink>
                                </td>
                                <td className="py-1">
                                    <NavLink
                                        to={routes.report(report.id)}
                                        className="text-blue-hyperlink break-all sm:break-words"
                                        onClick={() => onReportClick(report)}
                                    >
                                        {report.memo}
                                    </NavLink>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <div className="mx-auto my-5 flex flex-row flex-1 space-x-2">
                {prevExits && (
                    <NavLink
                        to={`${routes.daily()}?page=${pageStart - 2}`}
                        className="text-base text-black"
                    >
                        &lt;
                    </NavLink>
                )}
                {pageRange.map((r) => (
                    <NavLink
                        key={r}
                        to={`${routes.daily()}?page=${r - 1}`}
                        className={cn(
                            'text-base',
                            r - 1 === page ? 'text-blue-primary' : 'text-black',
                        )}
                    >
                        {r}
                    </NavLink>
                ))}
                {nextExists && (
                    <NavLink
                        to={`${routes.daily()}?page=${pageEnd}`}
                        className="text-base text-black"
                    >
                        &gt;
                    </NavLink>
                )}
            </div>
        </div>
    )
}
