import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import SingleLineInput from './SingleLineInput'
import { StudentContext } from 'Store/Student'
import {
    useDeleteStudent,
    useLoadStudent,
    useUploadStudent,
} from 'Action/student'
import Loader from 'Shared/Loader'
import cn from 'classnames'
import history from 'Util/history'
import routes from 'routes'

type Param = {
    id?: string | undefined
}

export default function Form() {
    const { id } = useParams<Param>()
    const studentId = parseInt(id ?? '0')
    const isCreate = parseInt(id ?? '0') === 0

    const {
        studentState: { student },
    } = useContext(StudentContext)
    const [isLoading, setLoading] = React.useState(false)
    const loadStudent = useLoadStudent()
    const uploadStudent = useUploadStudent()
    const deleteStudent = useDeleteStudent()

    const form = useForm({
        defaultValues: {
            name: student.name,
            tel: student.telephone,
            email: student.email,
            agency: student.agency,
            enrollment: student.enrollment,
            memo: student.memo,
        },
    })

    useEffect(() => {
        if (studentId && student.id === 0) {
            setLoading(true)
            loadStudent(studentId).then((s) => {
                if (s) {
                    console.log(s)
                    form.setValue('name', s.name)
                    form.setValue('tel', s.telephone)
                    form.setValue('email', s.email)
                    form.setValue('agency', s.agency)
                    form.setValue('enrollment', s.enrollment)
                    form.setValue('memo', s.memo)
                    setLoading(false)
                }
            })
        }
    }, [form])

    const onSubmit = async (values: {
        name: string
        tel: string
        email: string
        agency: string
        enrollment: string
        memo: string
    }) => {
        console.log(values)
        setLoading(true)
        await uploadStudent(studentId, {
            ...values,
            enrollment: values.enrollment === 'Enrolled' ? 1 : 0,
        })
        setLoading(false)
        history.push(routes.management())
    }
    const onDelete = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        if (
            window.confirm(
                '한번 삭제한 자료는 복구할 방법이 없습니다.\n\n정말 삭제하시겠습니까?',
            )
        ) {
            await deleteStudent(student)
            history.push(routes.management())
        }
    }

    return (
        <div className="w-full flex-1 bg-white mx-auto pt-9 px-7 flex flex-col">
            <span className="font-bold text-3xl ml-1.5">
                Student Management
            </span>
            {isLoading && (
                <Loader className="w-8 h-8 mt-2 mx-auto" color="#06ACEE" />
            )}
            <form
                className={cn('mt-3.5 flex flex-col', isLoading && 'hidden')}
                onSubmit={form.handleSubmit(onSubmit)}
            >
                <SingleLineInput form={form} name="name" />
                <SingleLineInput form={form} name="tel" />
                <SingleLineInput form={form} name="email" />
                <SingleLineInput form={form} name="agency" />
                <Controller
                    control={form.control}
                    name="enrollment"
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                    }) => (
                        <div
                            className="flex flex-row h-10 rounded shadow mb-2.5"
                            ref={ref}
                        >
                            <label
                                className="bg-blue-form w-24 text-base items-center justify-center inline-flex"
                                htmlFor="enrollment"
                            >
                                Enrollment
                            </label>
                            <span className="my-auto mx-2">Prospect</span>
                            <input
                                type="radio"
                                name={name}
                                value="Prospect"
                                className="my-auto"
                                checked={value === 'Prospect'}
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                            <span className="my-auto mx-2">Enrolled</span>
                            <input
                                type="radio"
                                name={name}
                                value="Enrolled"
                                className="my-auto"
                                checked={value === 'Enrolled'}
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        </div>
                    )}
                />
                <Controller
                    control={form.control}
                    name="memo"
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                    }) => (
                        <div
                            className="flex flex-row h-48 rounded shadow"
                            ref={ref}
                        >
                            <span className="bg-blue-form w-24 items-center justify-center inline-flex">
                                Memo
                            </span>
                            <textarea
                                className="text-black placeholder-gray-400 px-2 w-full border p-2"
                                placeholder="Memo"
                                onChange={onChange}
                                onBlur={onBlur}
                                name={name}
                                value={value || ''}
                            />
                        </div>
                    )}
                />
                <div className="ml-auto mt-8 mb-4">
                    {!isCreate && (
                        <button
                            className="w-20 mr-3 h-7 bg-gray-8b text-white"
                            onClick={onDelete}
                        >
                            Delete
                        </button>
                    )}
                    <button
                        type="submit"
                        className="w-20 h-7 bg-red-tint text-white"
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    )
}
