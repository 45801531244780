import React from 'react'
import cn from 'classnames'
import useHideBodyScroll from 'Util/useHideBodyScroll'

type Props = {
    children: React.ReactNode
    onClose?(): void
    className?: string
    width?: number | boolean
    scrollingElementRef?: { current: HTMLDivElement | null }
    scroll?: boolean
    hFull?: boolean
    pBottom?: string
    [key: string]: unknown
}

export default function Modal({
    children,
    onClose,
    className,
    width,
    scrollingElementRef,
    scroll = true,
    hFull,
    pBottom,
    ...props
}: Props) {
    useHideBodyScroll(scroll)

    return (
        <div
            className="fixed top-0 left-0 right-0 bottom-0 z-50 flex-center"
            style={{
                backdropFilter: 'blur(1px)',
                background: 'rgba(0, 0, 0, .1)',
            }}
            onClick={onClose}
            {...props}
        >
            <div
                className={cn(
                    'overflow-auto max-h-full py-10',
                    width !== false && 'w-full',
                    hFull && 'h-full',
                    pBottom && `pb-${pBottom}px`,
                )}
                ref={scrollingElementRef}
            >
                <div
                    className={cn(
                        'bg-white rounded-lg border border-gray-bb shadow w-full sm:w-4/5 mx-auto',
                        className,
                    )}
                    onClick={(e) => e.stopPropagation()}
                >
                    {children}
                </div>
            </div>
        </div>
    )
}
