import React, { useReducer } from 'react'
import {
    dailyReducer,
    initialState as dailyInitialState,
    DailyContext,
} from './Daily'
import {
    studentReducer,
    initialState as studentInitialState,
    StudentContext,
} from './Student'
import {
    userReducer,
    initialState as userInitialState,
    UserContext,
} from './User'
import {
    invoiceReducer,
    initialState as invoiceInitialState,
    InvoiceContext,
} from './Invoice'
import {
    classReducer,
    initialState as classInitialState,
    ClassContext,
} from './Class'

type Props = {
    children: React.ReactNode
}

export default function Store({ children }: Props) {
    const [dailyState, dispatchDailyState] = useReducer(
        dailyReducer,
        dailyInitialState,
    )
    const [studentState, dispatchStudentState] = useReducer(
        studentReducer,
        studentInitialState,
    )
    const [userState, dispatchUserState] = useReducer(
        userReducer,
        userInitialState,
    )
    const [invoiceState, dispatchInvoiceState] = useReducer(
        invoiceReducer,
        invoiceInitialState,
    )
    const [classState, dispatchClassState] = useReducer(
        classReducer,
        classInitialState,
    )
    return (
        <DailyContext.Provider
            value={{
                dailyState,
                dispatch: dispatchDailyState,
            }}
        >
            <StudentContext.Provider
                value={{
                    studentState,
                    dispatch: dispatchStudentState,
                }}
            >
                <UserContext.Provider
                    value={{
                        userState,
                        dispatch: dispatchUserState,
                    }}
                >
                    <InvoiceContext.Provider
                        value={{ invoiceState, dispatch: dispatchInvoiceState }}
                    >
                        <ClassContext.Provider
                            value={{ classState, dispatch: dispatchClassState }}
                        >
                            {children}
                        </ClassContext.Provider>
                    </InvoiceContext.Provider>
                </UserContext.Provider>
            </StudentContext.Provider>
        </DailyContext.Provider>
    )
}
