import { getUserToken, setCurrentUser } from 'Store/User'
import history from 'Util/history'
import routes from '../routes'

type RequestParam = {
    path: string
    method: 'GET' | 'POST' | 'PUT' | 'DELETE'
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options?: any
    params?: Record<string, string | number | undefined>
    data?: unknown
}

const paramsToSearch = (
    params?: Record<string, string | number | undefined>,
) => {
    if (!params) return ''

    const filtered: Record<string, string> = {}
    for (const key in params)
        if (params[key] !== undefined) filtered[key] = params[key] as string

    const str = new URLSearchParams(filtered).toString()
    return str ? `?${str}` : ''
}

export default async function request({
    path,
    method,
    options = {},
    params,
    data,
}: RequestParam) {
    options.method = method
    if (!options.headers) options.headers = {}
    const { headers } = options
    headers.accept = 'application/json'

    const token = getUserToken()
    if (token) {
        headers.authorization = token
    }

    if (data) {
        options.body = JSON.stringify(data)
    }

    const baseURL = process.env.REACT_APP_API_URL

    const response = await fetch(
        `${baseURL}${path}${paramsToSearch(params)}`,
        options,
    )

    if (response.status === 401) {
        // Invalid token
        setCurrentUser(null)
        history.push(routes.login())
        throw new Error('Unauthorized')
    }

    const contentType = response.headers.get('Content-Type')
    const isJSON = contentType?.includes('application/json')

    // eslint-disable-next-line
    let body: any
    try {
        body = await (isJSON ? response.json() : response.text())
    } catch (err) {
        body = undefined
    }

    if (!response.ok) throw new Error(body.error || body)

    return body
}
