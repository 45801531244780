import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { DailyContext } from 'Store/Daily'
import Loader from 'Shared/Loader'
import { useDeleteReport, useLoadReport, useUploadReport } from 'Action/daily'
import cn from 'classnames'
import history from 'Util/history'
import routes from '../../routes'

type Param = {
    id?: string | undefined
}

export default function Form() {
    const { id } = useParams<Param>()
    const reportId = parseInt(id ?? '0')
    const isCreate = parseInt(id ?? '0') === 0
    const {
        dailyState: { report },
    } = useContext(DailyContext)
    const [isLoading, setLoading] = React.useState(false)
    const loadReport = useLoadReport()
    const uploadReport = useUploadReport()
    const deleteReport = useDeleteReport()

    const form = useForm({
        defaultValues: {
            payment: report.payment,
            memo: report.memo,
        },
    })

    useEffect(() => {
        if (reportId && report.id === 0) {
            setLoading(true)
            loadReport(reportId).then((r) => {
                if (r) {
                    console.log(r)
                    form.setValue('payment', r.payment)
                    form.setValue('memo', r.memo)
                    setLoading(false)
                }
            })
        }
    }, [form])

    const onSubmit = async (values: { payment: string; memo: string }) => {
        setLoading(true)
        await uploadReport(reportId, values)
        setLoading(false)
        history.push(routes.daily())
    }
    const onDelete = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        if (
            window.confirm(
                '한번 삭제한 자료는 복구할 방법이 없습니다.\n\n정말 삭제하시겠습니까?',
            )
        ) {
            await deleteReport(report)
            history.push(routes.daily())
        }
    }

    return (
        <div className="w-full flex-1 bg-white mx-auto pt-9 px-7 flex flex-col mb-0.5 flex-grow">
            <span className="font-bold text-3xl ml-1.5">Daily Report</span>
            {isLoading && (
                <Loader className="w-8 h-8 mt-2 mx-auto" color="#06ACEE" />
            )}
            <form
                className={cn('mt-3.5 flex flex-col', isLoading && 'hidden')}
                onSubmit={form.handleSubmit(onSubmit)}
            >
                <Controller
                    control={form.control}
                    name="payment"
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                    }) => (
                        <div
                            className="flex flex-row h-24 rounded shadow"
                            ref={ref}
                        >
                            <span className="bg-blue-form w-24 items-center justify-center inline-flex">
                                Payment
                            </span>
                            <textarea
                                className="text-black placeholder-gray-400 px-2 w-full border p-2"
                                placeholder="Payment"
                                onChange={onChange}
                                onBlur={onBlur}
                                name={name}
                                value={value || ''}
                            />
                        </div>
                    )}
                />
                <Controller
                    control={form.control}
                    name="memo"
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                    }) => (
                        <div
                            className="flex flex-row h-48 rounded shadow mt-2.5"
                            ref={ref}
                        >
                            <span className="bg-blue-form w-24 items-center justify-center inline-flex">
                                Memo
                            </span>
                            <textarea
                                className="text-black placeholder-gray-400 px-2 w-full border p-2"
                                placeholder="Memo"
                                onChange={onChange}
                                onBlur={onBlur}
                                name={name}
                                value={value || ''}
                            />
                        </div>
                    )}
                />
                <div className="ml-auto mt-8 mb-4">
                    {!isCreate && (
                        <button
                            className="w-20 mr-3 h-7 bg-gray-8b text-white"
                            onClick={onDelete}
                        >
                            Delete
                        </button>
                    )}
                    <button
                        type="submit"
                        className="w-20 h-7 bg-red-tint text-white"
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    )
}
