import React from 'react'
import cn from 'classnames'
import { Controller, UseFormReturn } from 'react-hook-form'

type Props = {
    // eslint-disable-next-line
    form: UseFormReturn<any>
    name: string
    label?: string
    required?: boolean
    classes?: {
        root?: string
        label?: string
        input?: string
        inputError?: string
        error?: string
    }
    [key: string]: unknown
}

export default function Input({
    form,
    name,
    label,
    required,
    classes = {},
    ...props
}: Props) {
    const { control, formState } = form
    const errors = formState.errors
    const error = errors[name]
    const hasError =
        error && (formState.isSubmitted || formState.touchedFields[name])

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, onBlur, value, name, ref } }) => {
                return (
                    <div className={classes.root} ref={ref}>
                        {label && (
                            <label className={classes.label} htmlFor={name}>
                                {label}
                                {required && ' *'}
                            </label>
                        )}
                        <input
                            id={name}
                            name={name}
                            value={value || ''}
                            onChange={onChange}
                            onBlur={onBlur}
                            required={required}
                            placeholder={name}
                            className={cn(
                                classes.input,
                                hasError && classes.inputError,
                            )}
                            {...props}
                        />
                        {hasError && error && (
                            <div className={classes.error}>{error.message}</div>
                        )}
                    </div>
                )
            }}
        />
    )
}
