import request from 'Util/request'
import { DailyReport } from '../Store/Daily'
import dayjs from 'dayjs'

type ReportResponse = {
    id: number
    writer: string
    payment: string
    memo: string
    date: string
}
function mapReport(report: ReportResponse): DailyReport {
    return {
        ...report,
        date: dayjs(report.date).toDate(),
    }
}

export async function loadReports(page: number) {
    const reports = await request({
        method: 'GET',
        path: 'daily',
        params: {
            page,
        },
    })
    return reports.map(mapReport)
}

export async function loadReport(id: number) {
    const report = await request({
        method: 'GET',
        path: 'report',
        params: {
            id,
        },
    })
    return mapReport(report)
}

export async function loadPageCount() {
    const response = await request({
        method: 'GET',
        path: 'daily_count',
    })
    return response.count
}

export type ReportBody = {
    id?: number
    payment: string
    memo: string
}
export async function uploadReport(
    id: number,
    report: ReportBody,
): Promise<DailyReport> {
    if (id > 0) {
        report.id = id
    }
    const response = await request({
        method: 'POST',
        path: 'create_report',
        data: report,
    })
    return response as DailyReport
}

export async function deleteReport(id: number) {
    return await request({
        method: 'DELETE',
        path: 'delete_report',
        params: {
            id,
        },
    })
}
