import { useContext } from 'react'
import { ActionType, UserContext, User } from 'Store/User'
import { createUser, deleteUser, loadUsers, UserBody } from 'Admin/api'

export function useLoadUsers() {
    const { dispatch } = useContext(UserContext)
    return async () => {
        try {
            const users = await loadUsers()
            dispatch({
                type: ActionType.UsersLoaded,
                users,
            })
        } catch (e) {
            console.error(e)
        }
    }
}

export function useSetUser() {
    const { dispatch } = useContext(UserContext)
    return async (user: User | undefined) => {
        if (user) {
            dispatch({
                type: ActionType.UserLoaded,
                user,
            })
        } else {
            dispatch({
                type: ActionType.UserCleared,
            })
        }
    }
}

export function useDeleteUser() {
    const { userState, dispatch } = useContext(UserContext)
    return async (user: User) => {
        try {
            await deleteUser(user.id)
            const users = userState.users.filter((u) => u.id !== user.id)
            dispatch({
                type: ActionType.UsersLoaded,
                users,
            })
        } catch (e) {
            console.error(e)
        }
    }
}

export function useCreateUser() {
    const { dispatch } = useContext(UserContext)
    return async (id: number, data: UserBody) => {
        try {
            const user = await createUser(id, data)
            dispatch({
                type: ActionType.UserAdded,
                user,
            })
        } catch (e) {
            console.error(e)
        }
    }
}
