import request from 'Util/request'
import { Student } from 'Store/Student'
import dayjs from 'dayjs'

type StudentResponse = {
    id: number
    name: string
    telephone: string
    agency: string
    email: string
    enrollment: number
    memo: string
    date: string
}
export function mapStudent(student: StudentResponse): Student {
    return {
        ...student,
        enrollment: student.enrollment === 1 ? 'Enrolled' : 'Prospect',
        date: dayjs(student.date).toDate(),
    }
}

export async function loadStudents(page: number, searchKey?: string) {
    const students = await request({
        method: 'GET',
        path: 'management',
        params: {
            page,
            searchKey,
        },
    })
    return students.map(mapStudent)
}

export async function loadStudent(id: number) {
    const student = await request({
        method: 'GET',
        path: 'student',
        params: {
            id,
        },
    })
    return mapStudent(student)
}

export async function loadPageCount() {
    const response = await request({
        method: 'GET',
        path: 'management_count',
    })
    return response.count
}

export type StudentBody = {
    id?: number
    name: string
    tel: string
    email: string
    agency: string
    enrollment: number
    memo: string
}
export async function uploadStudent(
    id: number,
    student: StudentBody,
): Promise<Student> {
    if (id > 0) {
        student.id = id
    }
    const response = await request({
        method: 'POST',
        path: 'create_student',
        data: student,
    })
    return mapStudent(response)
}

export async function deleteStudent(id: number) {
    return await request({
        method: 'DELETE',
        path: 'delete_student',
        params: {
            id,
        },
    })
}
