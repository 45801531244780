import {
    addStudent,
    ClassBody,
    createClass,
    deleteClass,
    loadClass,
    loadClasses,
    loadPageCount,
    loadStudentsInClass,
    loadStudentsInClassCount,
    loadStudentsNotInClass,
    loadStudentsNotInClassCount,
    modifyStudent,
    removeStudent,
} from 'Class/api'
import { useContext } from 'react'
import {
    ActionType,
    ClassContext,
    ClassStudent,
    HeraldClass,
} from 'Store/Class'
import assert from 'assert'
import dayjs from 'dayjs'
import { Student } from 'Store/Student'

export function useLoadPageCount() {
    const { dispatch } = useContext(ClassContext)
    return async () => {
        try {
            const count = await loadPageCount()
            dispatch({
                type: ActionType.ClassPagesLoaded,
                count,
            })
        } catch (e) {
            console.error(e)
        }
    }
}

export function useSetModifyingClass() {
    const { dispatch } = useContext(ClassContext)
    return async (modifyingClass?: HeraldClass) => {
        await dispatch({
            type: ActionType.ClassModifyingSet,
            class: modifyingClass,
        })
    }
}

export function useSetClass() {
    const { dispatch } = useContext(ClassContext)
    return async (heraldClass: HeraldClass) => {
        await dispatch({
            type: ActionType.ClassLoaded,
            class: heraldClass,
        })
    }
}

export function useLoadClasses() {
    const { dispatch } = useContext(ClassContext)
    return async (page: number) => {
        try {
            const classes = await loadClasses(page)
            await dispatch({
                type: ActionType.ClassesLoaded,
                page,
                classes,
            })
        } catch (e) {
            console.error(e)
        }
    }
}

export function useLoadClass() {
    const { dispatch } = useContext(ClassContext)
    return async (id: number) => {
        try {
            const heraldClass = await loadClass(id)
            await dispatch({
                type: ActionType.ClassLoaded,
                class: heraldClass,
            })
        } catch (e) {
            console.error(e)
        }
    }
}

export function useDeleteClass() {
    const { dispatch } = useContext(ClassContext)
    return async (heraldClass: HeraldClass) => {
        try {
            await deleteClass(heraldClass.id)
            dispatch({
                type: ActionType.ClassesClear,
            })
        } catch (e) {
            console.error(e)
        }
    }
}

export function useCreateClass() {
    const { dispatch } = useContext(ClassContext)
    return async (id: number, data: ClassBody) => {
        try {
            const heraldClass = await createClass(id, data)
            dispatch({
                type: ActionType.ClassAdded,
                class: heraldClass,
            })
            dispatch({
                type: ActionType.ClassModifyingSet,
                class: undefined,
            })
        } catch (e) {
            console.error(e)
        }
    }
}

export function useLoadStudentsInClass() {
    const { dispatch } = useContext(ClassContext)
    return async (id: number, page: number) => {
        try {
            const students = await loadStudentsInClass(id, page)
            await dispatch({
                type: ActionType.ClassStudentsInLoaded,
                students,
                page,
            })
        } catch (e) {
            console.error(e)
        }
    }
}

export function useLoadStudentsInClassCount() {
    const { dispatch } = useContext(ClassContext)
    return async (id: number) => {
        try {
            const count = await loadStudentsInClassCount(id)
            dispatch({
                type: ActionType.ClassStudentsInCountLoaded,
                count,
            })
        } catch (e) {
            console.error(e)
        }
    }
}

export function useLoadStudentsNotInClass() {
    const { dispatch } = useContext(ClassContext)
    return async (id: number, page: number, searchKey?: string) => {
        try {
            const students = await loadStudentsNotInClass(id, page, searchKey)
            await dispatch({
                type: ActionType.ClassStudentsNotInLoaded,
                students,
                page,
            })
        } catch (e) {
            console.error(e)
        }
    }
}

export function useLoadStudentsNotInClassCount() {
    const { dispatch } = useContext(ClassContext)
    return async (id: number) => {
        try {
            const count = await loadStudentsNotInClassCount(id)
            dispatch({
                type: ActionType.ClassStudentsNotInCountLoaded,
                count,
            })
        } catch (e) {
            console.error(e)
        }
    }
}

export function useSetModifyingStudent() {
    const { dispatch } = useContext(ClassContext)
    return async (student?: ClassStudent) => {
        try {
            dispatch({
                type: ActionType.ClassStudentModifyingSet,
                student,
            })
        } catch (e) {
            console.error(e)
        }
    }
}

export function useUpdateStudentData() {
    const { classState, dispatch } = useContext(ClassContext)
    return async (start: string, end: string) => {
        try {
            const student = classState.modifyingStudent
            assert(student !== undefined)
            await modifyStudent(
                classState.class.id,
                student.student.id,
                start,
                end,
            )
            student.start = dayjs(start, 'YYYY-MM-DD').toDate()
            student.end = dayjs(end, 'YYYY-MM-DD').toDate()
            dispatch({
                type: ActionType.ClassStudentAdded,
                student,
            })
            dispatch({
                type: ActionType.ClassStudentModifyingSet,
                student: undefined,
            })
        } catch (e) {
            console.error(e)
        }
    }
}

export function useAddStudent() {
    const { classState, dispatch } = useContext(ClassContext)
    return async (student: Student) => {
        try {
            await addStudent(classState.class, student)
            dispatch({
                type: ActionType.ClassStudentAdded,
                student: {
                    start: new Date(),
                    end: new Date(),
                    student,
                },
            })
        } catch (e) {
            console.error(e)
        }
    }
}

export function useRemoveStudent() {
    const { classState, dispatch } = useContext(ClassContext)
    return async (student: ClassStudent) => {
        try {
            await removeStudent(classState.class, student.student)
            dispatch({
                type: ActionType.ClassLoaded,
                class: classState.class,
            })
        } catch (e) {
            console.error(e)
        }
    }
}

export function useClearNotInClassStudents() {
    const { dispatch } = useContext(ClassContext)
    return async () => {
        dispatch({
            type: ActionType.ClassStudentsNotInClear,
        })
    }
}
