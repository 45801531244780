import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { User } from 'Store/User'

type Props = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form: UseFormReturn<any>
    user: User
    className: string
    shouldShowForm: boolean
    hideDelete?: boolean
    onModifyClick: (user: User) => void
    onDeleteClick: (user: User) => void
}

export default function Row({
    form,
    user,
    className,
    shouldShowForm,
    hideDelete,
    onModifyClick,
    onDeleteClick,
}: Props) {
    if (shouldShowForm) {
        return (
            <tr className={className}>
                <td>{user.id}</td>
                <td>
                    <input {...form.register('login')} placeholder="ID" />
                </td>
                <td>
                    <input {...form.register('name')} placeholder="Name" />
                </td>
                <td>
                    <input
                        {...form.register('password')}
                        type="password"
                        placeholder="Password"
                    />
                </td>
                <td className="text-left">
                    <button
                        type="submit"
                        className="text-base bg-red-tint h-7 w-20 text-center text-white rounded ml-1 my-auto"
                    >
                        Save
                    </button>
                </td>
            </tr>
        )
    } else {
        const onModify = (e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault()
            onModifyClick(user)
        }
        const onDelete = (e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault()
            onDeleteClick(user)
        }
        return (
            <tr className={className}>
                <td>{user.id}</td>
                <td>{user.login}</td>
                <td>{user.name}</td>
                <td className="hidden md:block">••••••••••</td>
                <td className="md:hidden">••••</td>
                <td className="text-left">
                    <div className="flex flex-col md:flex-row">
                        <button
                            className="text-base border border-gray-35 h-7 w-20 text-center text-gray-35 rounded my-auto ml-1 mt-2 md:mt-0 mb-2 md:mb-0"
                            onClick={onModify}
                        >
                            Modify
                        </button>
                        {!hideDelete && (
                            <button
                                className="text-base bg-gray-35 h-7 w-20 text-center text-white rounded my-auto ml-1 md:ml-2 mt-2 md:mt-0 mb-2 md:mb-0"
                                onClick={onDelete}
                            >
                                Delete
                            </button>
                        )}
                        {hideDelete && (
                            <div className="h-7 w-20 my-auto ml-1 md:ml-2 mt-2 md:mt-0 mb-2 md:mb-0" />
                        )}
                    </div>
                </td>
            </tr>
        )
    }
}
