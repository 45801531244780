import { useContext } from 'react'
import { ActionType, DailyContext, DailyReport } from 'Store/Daily'
import {
    deleteReport,
    loadPageCount,
    loadReport,
    loadReports,
    ReportBody,
    uploadReport,
} from '../Daily/api'

export function useLoadPageCount() {
    const { dispatch } = useContext(DailyContext)
    return async () => {
        try {
            const count = await loadPageCount()
            dispatch({
                type: ActionType.ReportPagesLoaded,
                count,
            })
        } catch (e) {
            console.error(e)
        }
    }
}

export function useLoadReports() {
    const { dispatch } = useContext(DailyContext)
    return async (page: number) => {
        try {
            const reports = await loadReports(page)
            dispatch({
                type: ActionType.ReportsLoaded,
                page,
                reports,
            })
        } catch (e) {
            console.error(e)
        }
    }
}

export function useLoadReport() {
    const { dispatch } = useContext(DailyContext)
    return async (id: number) => {
        try {
            const report = await loadReport(id)
            dispatch({
                type: ActionType.ReportLoaded,
                report: report,
            })
            return report
        } catch (e) {
            console.error(e)
        }
    }
}

export function useSetReport() {
    const { dispatch } = useContext(DailyContext)
    return async (report: DailyReport) => {
        dispatch({
            type: ActionType.ReportLoaded,
            report,
        })
    }
}

export function useUploadReport() {
    const { dispatch } = useContext(DailyContext)
    return async (id: number, values: ReportBody) => {
        const report = await uploadReport(id, values)
        dispatch({
            type: ActionType.ReportAdded,
            report,
        })
    }
}

export function useDeleteReport() {
    const { dispatch } = useContext(DailyContext)
    return async (report: DailyReport) => {
        try {
            await deleteReport(report.id)
            dispatch({
                type: ActionType.ReportsClear,
            })
        } catch (e) {
            console.error(e)
        }
    }
}
