import request from 'Util/request'
import { ClassStudent, HeraldClass } from 'Store/Class'
import { Student } from 'Store/Student'
import { mapStudent as mapNormalStudent } from 'Management/api'
import dayjs from 'dayjs'

export async function loadClasses(page: number): Promise<HeraldClass[]> {
    return await request({
        method: 'GET',
        path: 'classes',
        params: {
            page,
        },
    })
}

export async function loadClass(id: number): Promise<HeraldClass> {
    return await request({
        method: 'GET',
        path: 'class',
        params: {
            id,
        },
    })
}

export async function loadPageCount() {
    const response = await request({
        method: 'GET',
        path: 'class_count',
    })
    return response.count
}

type StudentResponse = {
    id: number
    name: string
    telephone: string
    agency: string
    email: string
    enrollment: number
    memo: string
    date: string
    start: string
    end: string
}
function mapStudent(raw: StudentResponse): ClassStudent {
    const { start, end, ...student } = raw
    const studentValue: Student = {
        ...student,
        enrollment: student.enrollment === 1 ? 'Enrolled' : 'Prospect',
        date: dayjs(student.date).toDate(),
    }
    return {
        start: dayjs(start).toDate(),
        end: dayjs(end).toDate(),
        student: studentValue,
    }
}
export async function loadStudentsInClass(id: number, page: number) {
    const response = await request({
        method: 'GET',
        path: 'students_in_class',
        params: {
            class_id: id,
            page,
        },
    })
    return response.map(mapStudent)
}

export async function loadStudentsNotInClass(
    id: number,
    page: number,
    searchKey?: string,
) {
    const response = await request({
        method: 'GET',
        path: 'students_not_in_class',
        params: {
            class_id: id,
            page,
            searchKey,
        },
    })
    return response.map(mapNormalStudent)
}

export async function loadStudentsInClassCount(id: number) {
    const response = await request({
        method: 'GET',
        path: 'students_in_class_count',
        params: {
            class_id: id,
        },
    })
    return response.count
}

export async function loadStudentsNotInClassCount(id: number) {
    const response = await request({
        method: 'GET',
        path: 'students_not_in_class_count',
        params: {
            class_id: id,
        },
    })
    return response.count
}

export async function deleteClass(id: number) {
    return await request({
        method: 'DELETE',
        path: 'delete_class',
        params: {
            id,
        },
    })
}

export type ClassBody = {
    id?: number
    name: string
    date: string
}
export async function createClass(id: number, data: ClassBody) {
    if (id > 0) {
        data.id = id
    }
    return await request({
        method: 'POST',
        path: 'create_class',
        data,
    })
}

export async function addStudent(heraldClass: HeraldClass, student: Student) {
    return await request({
        method: 'POST',
        path: 'add_student',
        data: {
            class_id: heraldClass.id,
            student_id: student.id,
        },
    })
}

export async function removeStudent(
    heraldClass: HeraldClass,
    student: Student,
) {
    return await request({
        method: 'DELETE',
        path: 'remove_student',
        params: {
            class_id: heraldClass.id,
            student_id: student.id,
        },
    })
}

export async function modifyStudent(
    class_id: number,
    student_id: number,
    start: string,
    end: string,
) {
    return await request({
        method: 'PUT',
        path: 'update_student_data',
        data: {
            class_id,
            student_id,
            start,
            end,
        },
    })
}
