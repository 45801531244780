import React from 'react'
import cn from 'classnames'

type Props = {
    name: string
    label: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
    classes?: {
        root?: string
        label?: string
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (value: any) => void
}

export default function Radio({
    name,
    label,
    value,
    values,
    classes,
    onChange,
}: Props) {
    const change = (e: React.FormEvent<HTMLInputElement>) => {
        const name = e.currentTarget.name
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const value: any = {}
        value[name] = e.currentTarget.value
        onChange(value)
    }
    return (
        <div
            className={cn(
                'flex flex-row h-10 rounded shadow mb-2.5',
                classes?.root,
            )}
        >
            <label
                className={cn(
                    'bg-blue-form w-24 text-base items-center justify-center inline-flex',
                    classes?.label,
                )}
                htmlFor={name}
            >
                {label}
            </label>
            {values.map((v) => (
                <React.Fragment key={v}>
                    <span className="my-auto mx-2">{v}</span>
                    <input
                        type="radio"
                        name={name}
                        value={v}
                        className="my-auto"
                        checked={value === v}
                        onChange={change}
                    />
                </React.Fragment>
            ))}
        </div>
    )
}
