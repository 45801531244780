import { useContext } from 'react'
import { ActionType, StudentContext, Student } from 'Store/Student'
import {
    deleteStudent,
    loadPageCount,
    loadStudent,
    loadStudents,
    StudentBody,
    uploadStudent,
} from 'Management/api'

export function useLoadPageCount() {
    const { dispatch } = useContext(StudentContext)
    return async () => {
        try {
            const count = await loadPageCount()
            dispatch({
                type: ActionType.StudentPagesLoaded,
                count,
            })
        } catch (e) {
            console.error(e)
        }
    }
}

export function useLoadStudents() {
    const { dispatch } = useContext(StudentContext)
    return async (page: number, searchKey?: string) => {
        try {
            const students = await loadStudents(page, searchKey)
            dispatch({
                type: ActionType.StudentsLoaded,
                page,
                students,
            })
        } catch (e) {
            console.error(e)
        }
    }
}

export function useLoadStudent() {
    const { dispatch } = useContext(StudentContext)
    return async (id: number) => {
        try {
            const student = await loadStudent(id)
            dispatch({
                type: ActionType.StudentLoaded,
                student,
            })
            return student
        } catch (e) {
            console.error(e)
        }
    }
}

export function useSetStudent() {
    const { dispatch } = useContext(StudentContext)
    return async (student: Student) => {
        dispatch({
            type: ActionType.StudentLoaded,
            student,
        })
    }
}

export function useUploadStudent() {
    const { dispatch } = useContext(StudentContext)
    return async (id: number, values: StudentBody) => {
        const student = await uploadStudent(id, values)
        dispatch({
            type: ActionType.StudentAdded,
            student,
        })
    }
}

export function useClearStudents() {
    const { dispatch } = useContext(StudentContext)
    return async () => {
        dispatch({
            type: ActionType.StudentsClear,
        })
    }
}

export function useDeleteStudent() {
    const { dispatch } = useContext(StudentContext)
    return async (report: Student) => {
        try {
            await deleteStudent(report.id)
            dispatch({
                type: ActionType.StudentsClear,
            })
        } catch (e) {
            console.error(e)
        }
    }
}
