import { createContext, Dispatch } from 'react'
import { replaceExistence } from 'Util/existence'

export enum ActionType {
    ReportAdded = 'reportAdded',
    ReportsLoaded = 'reportsLoaded',
    ReportLoaded = 'reportLoaded',
    ReportPagesLoaded = 'reportPagesLoaded',
    ReportsClear = 'reportsClear',
}

export type DailyReport = {
    id: number
    writer: string
    payment: string
    memo: string
    date: Date
}
export const emptyReport: DailyReport = {
    id: 0,
    writer: '',
    payment: '',
    memo: '',
    date: new Date(),
}

export type DailyState = {
    reports: { [key: number]: DailyReport[] }
    report: DailyReport
    pageCount: number
}

export type DailyAction =
    | {
          type: ActionType.ReportPagesLoaded
          count: number
      }
    | {
          type: ActionType.ReportsLoaded
          page: number
          reports: DailyReport[]
      }
    | {
          type: ActionType.ReportLoaded
          report: DailyReport
      }
    | {
          type: ActionType.ReportAdded
          report: DailyReport
      }
    | {
          type: ActionType.ReportsClear
      }

export type DailyContextType = {
    dailyState: DailyState
    dispatch: Dispatch<DailyAction>
}

export const initialState: DailyState = {
    reports: {},
    report: emptyReport,
    pageCount: 0,
}

export const dailyReducer = (
    state: DailyState,
    action: DailyAction,
): DailyState => {
    switch (action.type) {
        case ActionType.ReportPagesLoaded:
            return {
                ...state,
                pageCount: action.count,
            }
        case ActionType.ReportsLoaded:
            // eslint-disable-next-line no-case-declarations
            const reports = state.reports
            reports[action.page] = action.reports
            return {
                ...state,
                reports,
            }
        case ActionType.ReportLoaded:
            return {
                ...state,
                report: action.report,
            }
        case ActionType.ReportAdded:
            return {
                ...state,
                reports: replaceExistence(state.reports, action.report),
            }
        case ActionType.ReportsClear:
            return {
                ...state,
                reports: {},
            }
        default:
            return initialState
    }
}

export const DailyContext = createContext<DailyContextType>(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    undefined,
)
