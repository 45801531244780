import React from 'react'
import { NavLink } from 'react-router-dom'
import routes from 'routes'
import { ClassContext, emptyClass } from 'Store/Class'
import { pageValues } from 'Util/page'
import cn from 'classnames'
import {
    useCreateClass,
    useLoadClasses,
    useLoadPageCount,
    useSetModifyingClass,
} from 'Action/class'
import { useForm } from 'react-hook-form'
import Row from './Form/Row'
import assert from 'assert'

export default function Class() {
    const page =
        parseInt(
            new URLSearchParams(window.location.search).get('page') ?? '0',
        ) ?? 0
    const { classState } = React.useContext(ClassContext)
    const loadPageCount = useLoadPageCount()
    const loadClasses = useLoadClasses()
    const createClass = useCreateClass()

    const setModifyingClass = useSetModifyingClass()

    const form = useForm()

    React.useEffect(() => {
        if (classState.pageCount === 0) {
            loadPageCount().then()
        }
    }, [classState.pageCount])
    React.useEffect(() => {
        if (!classState.classes[page]) {
            loadClasses(page).then()
        }
    }, [page, classState.classes[page]])

    const onCreateClick = async () => {
        await setModifyingClass(emptyClass)
        form.setValue('name', emptyClass.name)
        form.setValue('date', emptyClass.date)
    }

    const onSubmit = async (values: { name: string; date: string }) => {
        assert(classState.modifyingClass !== undefined)
        await createClass(classState.modifyingClass?.id, values)
    }

    const { pageStart, pageEnd, prevExits, nextExists, pageRange } = pageValues(
        page,
        classState.pageCount,
    )
    const isSmallDisplay = window.matchMedia('(max-width: 768px)').matches

    return (
        <div className="w-full flex-1 mx-auto pt-9 px-2 sm:px-7 flex flex-col">
            <div className="flex flex-row">
                <span className="font-bold text-3xl ml-1.5">Class</span>
                <button
                    className="text-base bg-red-tint h-7 w-20 text-center text-white rounded ml-auto my-auto"
                    onClick={onCreateClick}
                >
                    Create
                </button>
            </div>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <table className="w-full table-fixed sm:table-auto mt-3.5 text-center border-separate">
                    <thead>
                        <tr className="bg-blue-thead">
                            <th>No.</th>
                            <th>Class Name</th>
                            <th>Date &amp; Time</th>
                            <th className="hidden sm:table-cell">Management</th>
                            <th className="sm:hidden">Manage</th>
                        </tr>
                    </thead>
                    <tbody>
                        {classState.classes[page] &&
                            classState.classes[page].map((heraldClass, i) => (
                                <Row
                                    form={form}
                                    heraldClass={heraldClass}
                                    className={
                                        i % 2 === 0
                                            ? 'bg-white'
                                            : 'bg-blue-tbody'
                                    }
                                    isSmallDisplay={isSmallDisplay}
                                    showFormOnly={false}
                                    key={i}
                                />
                            ))}
                        {!isSmallDisplay &&
                            classState.modifyingClass &&
                            classState.modifyingClass.id === 0 && (
                                <Row
                                    form={form}
                                    heraldClass={classState.modifyingClass}
                                    className={
                                        classState.classes[page].length % 2 ===
                                        0
                                            ? 'bg-white'
                                            : 'bg-blue-tbody'
                                    }
                                    isSmallDisplay={isSmallDisplay}
                                    showFormOnly={false}
                                />
                            )}
                    </tbody>
                </table>
                {isSmallDisplay && classState.modifyingClass && (
                    <Row
                        form={form}
                        heraldClass={classState.modifyingClass}
                        className=""
                        isSmallDisplay={true}
                        showFormOnly={true}
                    />
                )}
            </form>
            <div className="mx-auto my-5 flex flex-row flex-1 space-x-2">
                {prevExits && (
                    <NavLink
                        to={`${routes.class()}?page=${pageStart - 2}`}
                        className="text-base text-black"
                    >
                        &lt;
                    </NavLink>
                )}
                {pageRange.map((r) => (
                    <NavLink
                        key={r}
                        to={`${routes.class()}?page=${r - 1}`}
                        className={cn(
                            'text-base',
                            r - 1 === page ? 'text-blue-primary' : 'text-black',
                        )}
                    >
                        {r}
                    </NavLink>
                ))}
                {nextExists && (
                    <NavLink
                        to={`${routes.class()}?page=${pageEnd}`}
                        className="text-base text-black"
                    >
                        &gt;
                    </NavLink>
                )}
            </div>
        </div>
    )
}
