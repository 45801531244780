import { createContext, Dispatch } from 'react'
import { getCurrentUser } from './User'
import dayjs from 'dayjs'

export enum ActionType {
    InvoiceSaved = 'InvoiceSaved',
    InvoiceCleared = 'InvoiceCleared',
}

export type Invoice = {
    invoiceNumber: string
    date: string
    agency: string
    start: string
    end: string
    program: string
    studentName: string
    isFromAgency: string
    registrationFee: string
    materialFee: string
    tuitionFee: string
    secondTuitionFee: string
    specialDiscount: string
    commission: string
}

function generateEmptyInvoice(): Invoice {
    const user = getCurrentUser()
    const branch = user ? user.branch : 'TO'
    const now = new Date()
    return {
        invoiceNumber: `${branch
            .toString()
            .substring(0, 2)
            .toUpperCase()}${dayjs(now).format('YYMMDD')}-`,
        date: `${dayjs(now).format('D/MM/YY')}`,
        studentName: '',
        program: '',
        start: '',
        end: '',
        agency: '',
        isFromAgency: 'Agency',
        registrationFee: '',
        materialFee: '',
        tuitionFee: '',
        secondTuitionFee: '',
        specialDiscount: '',
        commission: '',
    }
}
export const emptyInvoice = generateEmptyInvoice()

export type InvoiceState = {
    invoice: Invoice
}

export type InvoiceAction =
    | {
          type: ActionType.InvoiceSaved
          invoice: Invoice
      }
    | {
          type: ActionType.InvoiceCleared
      }

export type InvoiceContextType = {
    invoiceState: InvoiceState
    dispatch: Dispatch<InvoiceAction>
}

export const initialState: InvoiceState = {
    invoice: emptyInvoice,
}

export const invoiceReducer = (
    state: InvoiceState,
    action: InvoiceAction,
): InvoiceState => {
    switch (action.type) {
        case ActionType.InvoiceSaved:
            return {
                ...state,
                invoice: action.invoice,
            }
        case ActionType.InvoiceCleared:
            return {
                ...state,
                invoice: emptyInvoice,
            }
        default:
            return initialState
    }
}

export const InvoiceContext = createContext<InvoiceContextType>(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    undefined,
)
