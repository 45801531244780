import range from './range'

export function pageValues(page: number, total: number) {
    if (total === 0) {
        return {
            pageStart: 0,
            pageEnd: 0,
            prevExists: false,
            nextExits: false,
            pageRange: [],
        }
    }
    const pageStart = 10 * Math.floor(page / 10) + 1
    let pageEnd = pageStart + 9
    const prevExits = pageStart > 1
    let nextExists = true
    if (total > 0) {
        pageEnd = pageStart + 9 > total ? total : pageStart + 9
        nextExists = pageStart + 9 < total
    }
    const pageRange = range(pageStart, pageEnd - pageStart + 1)

    return {
        pageStart,
        pageEnd,
        prevExits,
        nextExists,
        pageRange,
    }
}
