import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { CurrentUser, useCurrentUser } from 'Store/User'
import routes from 'routes'
import Auth from 'Auth'
import Layout from 'App/Layout'
import Daily from 'Daily'
import Management from 'Management'
import { default as DailyForm } from 'Daily/Form'
import { default as StudentForm } from 'Management/Form'
import { default as ClassDetail } from 'Class/Detail'
import Admin from 'Admin'
import Invoice from 'Invoice'
import Class from 'Class'

function AuthRouter() {
    return (
        <Switch>
            <Route path={routes.login()} exact component={Auth} />
            <Redirect to={routes.login()} />
        </Switch>
    )
}

function UserRouter(props: { user: CurrentUser }) {
    const isAdmin = props.user ? props.user.isAdmin : false
    return (
        <Layout>
            <Switch>
                <Route path={routes.daily()} exact component={Daily} />
                <Route
                    path={routes.management()}
                    exact
                    component={Management}
                />
                {isAdmin && (
                    <Route path={routes.admin()} exact component={Admin} />
                )}
                <Route path={routes.invoice()} exact component={Invoice} />
                <Route path={routes.report()} exact component={DailyForm} />
                <Route path={routes.student()} exact component={StudentForm} />
                <Route path={routes.class()} exact component={Class} />
                <Route
                    path={routes.classDetail()}
                    exact
                    component={ClassDetail}
                />
                <Redirect to={routes.daily()} />
            </Switch>
        </Layout>
    )
}

export default function Router() {
    const [user] = useCurrentUser()
    return (
        <>
            {user && <UserRouter user={user} />}
            {!user && <AuthRouter />}
        </>
    )
}
