import React, { useEffect, useRef, useState } from 'react'
import Radio from '../Shared/Form/Radio'
import ReactToPrint from 'react-to-print'
import logo from 'assets/images/invoice_header.png'
import { useInvoice, useSetInvoice } from 'Action/invoice'
import { emptyInvoice, Invoice as InvoiceType } from 'Store/Invoice'

function toFloat(value: string) {
    const float = parseFloat(value)
    if (isNaN(float)) {
        return 0.0
    } else {
        return float
    }
}
function formatMoney(value: string) {
    if (value === '') {
        return ''
    }
    const float = toFloat(toFloat(value).toFixed(2))
    return '$' + float.toLocaleString()
}
function moneyToValue(money: string) {
    return money.replace(/(?!\.)\D/g, '').replace(/(?!^0$)^0+/g, '')
}

export default function Invoice() {
    const invoice = useInvoice()
    const setInvoice = useSetInvoice()
    const ref = useRef(null)
    const [invoiceValues, setInvoiceValues] = useState<InvoiceType>(invoice)

    useEffect(() => {
        setInvoice(invoiceValues).then()
    }, [invoiceValues])

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onChange = async (value: any) => {
        if (value.isFromAgency && value.isFromAgency === 'Student') {
            value.agency = 'Walk-in'
        }
        await setInvoiceValues({
            ...invoiceValues,
            ...value,
        })
    }
    const onReset = async () => {
        await setInvoiceValues(emptyInvoice)
    }
    const grossAmount =
        toFloat(invoiceValues.registrationFee) +
        toFloat(invoiceValues.materialFee) +
        toFloat(invoiceValues.tuitionFee) +
        toFloat(invoiceValues.secondTuitionFee)
    const netAmount =
        grossAmount -
        toFloat(invoiceValues.specialDiscount) -
        (invoiceValues.isFromAgency === 'Agency'
            ? toFloat(invoiceValues.commission)
            : 0.0)

    return (
        <div className="w-full flex-1 mx-auto py-9 px-7">
            <div className="flex flex-row">
                <span className="font-bold text-3xl ml-1.5">
                    Invoice Generator
                </span>
                <button
                    className="text-base bg-red-tint h-7 w-20 text-center text-white rounded ml-auto my-auto"
                    onClick={onReset}
                >
                    Reset
                </button>
                <ReactToPrint
                    bodyClass="flex flex-col flex-grow w-full h-screen"
                    documentTitle="Herald Invoice"
                    removeAfterPrint={true}
                    trigger={() => {
                        return (
                            <button className="ml-2 text-base bg-gray-35 h-7 w-20 text-center text-white rounded my-auto">
                                Print
                            </button>
                        )
                    }}
                    content={() => ref.current}
                />
            </div>
            <Radio
                name="isFromAgency"
                label={'Invoice Type'}
                values={['Agency', 'Student']}
                value={invoiceValues.isFromAgency}
                classes={{
                    root: 'mt-3.5',
                    label: 'w-32',
                }}
                onChange={onChange}
            />
            <div ref={ref} className="flex flex-col flex-grow m-10">
                <img src={logo} />
                <span className="font-bold text-2xl mt-1">
                    {invoiceValues.isFromAgency.toUpperCase()} INVOICE
                </span>
                <div className="ml-auto h-10 mt-3 flex flex-row">
                    <span className="text-base font-bold w-28 bg-blue-invoice h-full items-center justify-center inline-flex">
                        Invoice No.
                    </span>
                    <input
                        className="text-base text-black h-full w-36 border items-center justify-center inline-flex px-2"
                        value={invoiceValues.invoiceNumber}
                        onChange={(e) =>
                            onChange({ invoiceNumber: e.target.value })
                        }
                        placeholder="Invoice No."
                    />
                    <span className="text-base font-bold w-16 bg-blue-invoice h-full items-center justify-center inline-flex">
                        Date
                    </span>
                    <span className="text-base text-black h-full w-24 border items-center justify-center inline-flex">
                        {invoiceValues.date}
                    </span>
                </div>
                <div className="h-10 mt-3 flex flex-row">
                    <span className="text-base font-bold w-28 bg-blue-invoice h-full items-center justify-center inline-flex">
                        Agency
                    </span>
                    <input
                        className="text-base text-black h-full w-full border items-center justify-center inline-flex px-2"
                        value={invoiceValues.agency}
                        onChange={(e) => onChange({ agency: e.target.value })}
                        placeholder="Agency"
                    />
                </div>
                <div className="h-10 mt-4 flex flex-row">
                    <span className="text-base font-bold w-1/4 bg-blue-invoice h-full items-center justify-center inline-flex border-r border-blue-invoice-border">
                        Student Name
                    </span>
                    <span className="text-base font-bold w-1/4 bg-blue-invoice h-full items-center justify-center inline-flex border-r border-blue-invoice-border">
                        Program
                    </span>
                    <span className="text-base font-bold w-1/4 bg-blue-invoice h-full items-center justify-center inline-flex border-r border-blue-invoice-border">
                        Program Starts
                    </span>
                    <span className="text-base font-bold w-1/4 bg-blue-invoice h-full items-center justify-center inline-flex">
                        Program Ends
                    </span>
                </div>
                <div className="h-10 flex flex-row">
                    <input
                        className="text-base text-black h-full w-1/4 border items-center justify-center inline-flex pl-3"
                        value={invoiceValues.studentName}
                        onChange={(e) =>
                            onChange({ studentName: e.target.value })
                        }
                        placeholder="Student Name"
                    />
                    <input
                        className="text-base text-black h-full w-1/4 border items-center justify-center inline-flex pl-3"
                        value={invoiceValues.program}
                        onChange={(e) => onChange({ program: e.target.value })}
                        placeholder="Program"
                    />
                    <input
                        className="text-base text-black h-full w-1/4 border items-center justify-center inline-flex pl-3"
                        value={invoiceValues.start}
                        onChange={(e) => onChange({ start: e.target.value })}
                        placeholder="Program Starts"
                    />
                    <input
                        className="text-base text-black h-full w-1/4 border items-center justify-center inline-flex pl-3"
                        value={invoiceValues.end}
                        onChange={(e) => onChange({ end: e.target.value })}
                        placeholder="Program Ends"
                    />
                </div>

                <div className="flex flex-row bg-blue-invoice h-10 mt-7 text-base font-bold">
                    <span className="w-3/4 h-full items-center justify-center inline-flex border-r border-blue-invoice-border">
                        Description
                    </span>
                    <span className="w-1/4 h-full items-center justify-center inline-flex">
                        Amount
                    </span>
                </div>
                <div className="flex flex-row h-10 text-base border">
                    <span className="w-3/4 h-full items-center px-2 inline-flex border-r border-blue-invoice-border">
                        Registration Fee
                    </span>
                    <input
                        className="text-base text-black h-full w-1/4 border text-right pr-3"
                        value={formatMoney(invoiceValues.registrationFee)}
                        onChange={(e) =>
                            onChange({
                                registrationFee: moneyToValue(e.target.value),
                            })
                        }
                        placeholder="Registration Fee"
                    />
                </div>
                <div className="flex flex-row h-10 text-base border">
                    <span className="w-3/4 h-full items-center px-2 inline-flex border-r border-blue-invoice-border">
                        Material Fee
                    </span>
                    <input
                        className="text-base text-black h-full w-1/4 border text-right pr-3"
                        value={formatMoney(invoiceValues.materialFee)}
                        onChange={(e) =>
                            onChange({
                                materialFee: moneyToValue(e.target.value),
                            })
                        }
                        placeholder="Material Fee"
                    />
                </div>
                <div className="flex flex-row h-10 text-base border">
                    <span className="w-3/4 h-full items-center px-2 inline-flex border-r border-blue-invoice-border">
                        Tuition Fee
                    </span>
                    <input
                        className="text-base text-black h-full w-1/4 border text-right pr-3"
                        value={formatMoney(invoiceValues.tuitionFee)}
                        onChange={(e) =>
                            onChange({
                                tuitionFee: moneyToValue(e.target.value),
                            })
                        }
                        placeholder="Tuition Fee"
                    />
                </div>
                <div className="flex flex-row h-10 text-base border">
                    <span className="w-3/4 h-full items-center px-2 inline-flex border-r border-blue-invoice-border">
                        Tuition Fee
                    </span>
                    <input
                        className="text-base text-black h-full w-1/4 border text-right pr-3"
                        value={formatMoney(invoiceValues.secondTuitionFee)}
                        onChange={(e) =>
                            onChange({
                                secondTuitionFee: moneyToValue(e.target.value),
                            })
                        }
                        placeholder="Tuition Fee"
                    />
                </div>
                <div className="flex flex-row h-10 text-base border">
                    <span className="w-3/4 h-full items-center px-2 inline-flex border-r border-blue-invoice-border">
                        Special Discount
                    </span>
                    <input
                        className="text-base text-red-pure h-full w-1/4 border text-right pr-3"
                        value={formatMoney(invoiceValues.specialDiscount)}
                        onChange={(e) =>
                            onChange({
                                specialDiscount: moneyToValue(e.target.value),
                            })
                        }
                        placeholder="Discount"
                    />
                </div>
                <div className="flex flex-row h-10 text-base border">
                    <div className="w-3/4 h-full items-center px-2 inline-flex border-r border-blue-invoice-border" />
                    <div className="w-1/4 h-full items-center justify-end px-2 inline-flex" />
                </div>
                {invoiceValues.isFromAgency === 'Agency' && (
                    <div className="flex flex-row h-10 text-base border">
                        <span className="w-3/4 h-full items-center px-2 inline-flex border-r border-blue-invoice-border">
                            Commission
                        </span>
                        <input
                            className="text-base text-red-pure h-full w-1/4 border text-right pr-3"
                            value={formatMoney(invoiceValues.commission)}
                            onChange={(e) =>
                                onChange({
                                    commission: moneyToValue(e.target.value),
                                })
                            }
                            placeholder="Commission"
                        />
                    </div>
                )}
                {invoiceValues.isFromAgency === 'Student' && (
                    <div className="flex flex-row h-10 text-base border">
                        <div className="w-3/4 h-full items-center px-2 inline-flex border-r border-blue-invoice-border" />
                        <div className="w-1/4 h-full items-center justify-end px-2 inline-flex" />
                    </div>
                )}
                <div className="flex flex-row h-10 text-base border">
                    <div className="w-3/4 h-full items-center px-2 inline-flex border-r border-blue-invoice-border" />
                    <div className="w-1/4 h-full items-center justify-end px-2 inline-flex" />
                </div>
                <div className="flex flex-row h-10 text-base border">
                    <div className="w-3/4 h-full items-center px-2 inline-flex border-r border-blue-invoice-border" />
                    <div className="w-1/4 h-full items-center justify-end px-2 inline-flex" />
                </div>
                <div className="flex flex-row h-10 text-base border">
                    <div className="w-3/4 h-full items-center px-2 inline-flex border-r border-blue-invoice-border" />
                    <div className="w-1/4 h-full items-center justify-end px-2 inline-flex" />
                </div>
                <div className="flex flex-row bg-blue-invoice h-10 text-base font-bold">
                    <span className="w-3/4 h-full items-center inline-flex border-r border-blue-invoice-border px-2">
                        Gross Amount
                    </span>
                    <span className="w-1/4 h-full items-center justify-end inline-flex px-3">
                        ${grossAmount.toLocaleString()}
                    </span>
                </div>
                <div className="flex flex-row bg-blue-invoice h-10 text-base font-bold border-t border-blue-invoice-border">
                    <span className="w-3/4 h-full items-center inline-flex border-r border-blue-invoice-border px-2">
                        Net Amount
                    </span>
                    <span className="w-1/4 h-full items-center justify-end inline-flex px-3">
                        ${netAmount.toLocaleString()}
                    </span>
                </div>
                <span className="mt-3 text-xl font-bold mx-auto">
                    Payments:
                    <br />
                    Please write cheques payable to Herald Education Corp.
                </span>
            </div>
        </div>
    )
}
