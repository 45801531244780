import React, { useContext } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { ClassContext, ClassStudent } from 'Store/Class'
import { NavLink } from 'react-router-dom'
import routes from '../../routes'
import dayjs from 'dayjs'
import { Student } from '../../Store/Student'
import { useSetStudent } from '../../Action/student'
import { useRemoveStudent, useSetModifyingStudent } from '../../Action/class'

type Props = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form: UseFormReturn<any>
    student: ClassStudent
    className: string
}

export default function ClassStudentRow({ form, student, className }: Props) {
    const {
        classState: { modifyingStudent },
    } = useContext(ClassContext)
    const setStudent = useSetStudent()
    const setModifyingStudent = useSetModifyingStudent()
    const removeStudent = useRemoveStudent()

    const shouldShowForm =
        modifyingStudent && modifyingStudent.student.id === student.student.id

    const onModify = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        setModifyingStudent(student).then()
    }
    const onDelete = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        if (
            window.confirm(
                '한번 삭제한 자료는 복구할 방법이 없습니다.\n\n정말 삭제하시겠습니까?',
            )
        ) {
            removeStudent(student).then()
        }
    }
    const onCancel = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        setModifyingStudent().then()
    }
    const onStudentClick = (student: Student) => {
        setStudent(student).then()
    }
    return (
        <tr className={className}>
            <td>{student.student.id}</td>
            <td>
                <NavLink
                    to={routes.student(student.student.id)}
                    className="text-blue-hyperlink break-all sm:break-words"
                    onClick={() => onStudentClick(student.student)}
                >
                    {student.student.name}
                </NavLink>
            </td>
            <td className="hidden sm:table-cell">
                {student.student.telephone}
            </td>
            <td className="hidden sm:table-cell">
                <a
                    href={`mailto:${student.student.email}`}
                    className="text-blue-hyperlink"
                >
                    {student.student.email}
                </a>
            </td>
            <td className="break-all sm:break-words">
                {shouldShowForm && (
                    <input
                        {...form.register('start')}
                        type="date"
                        defaultValue={dayjs(student.start).format('YYYY-MM-DD')}
                    />
                )}
                {!shouldShowForm && dayjs(student.start).format('YYYY/MM/DD')}
            </td>
            <td className="break-all sm:break-words">
                {shouldShowForm && (
                    <input
                        {...form.register('end')}
                        type="date"
                        defaultValue={dayjs(student.end).format('YYYY-MM-DD')}
                    />
                )}
                {!shouldShowForm && dayjs(student.end).format('YYYY/MM/DD')}
            </td>
            <td className="text-left">
                {shouldShowForm && (
                    <div className="flex flex-col pl-1">
                        <button
                            className="text-base border border-gray-35 h-7 w-20 text-center text-gray-35 rounded mt-2 mb-1"
                            onClick={onCancel}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="text-base bg-red-tint h-7 w-20 text-center text-white rounded mt-1 mb-2"
                        >
                            Save
                        </button>
                    </div>
                )}
                {!shouldShowForm && (
                    <div className="flex flex-col sm:flex-row">
                        <button
                            className="text-base border border-gray-35 h-7 w-16 sm:w-20 text-center text-gray-35 rounded my-auto ml-1 mt-2 md:mt-0 mb-2 md:mb-0"
                            onClick={onModify}
                        >
                            Modify
                        </button>
                        <button
                            className="text-base bg-gray-35 h-7 w-16 sm:w-20 text-center text-white rounded my-auto ml-1 md:ml-2 mt-2 md:mt-0 mb-2 md:mb-0"
                            onClick={onDelete}
                        >
                            Delete
                        </button>
                    </div>
                )}
            </td>
        </tr>
    )
}
