import { Branch, CurrentUser, setCurrentUser } from 'Store/User'
import history from 'Util/history'
import request from 'Util/request'
import routes from 'routes'

type UserResponse = {
    id: number
    token: string
    is_admin: number
    name: string
    login: string
    branch: number
}
function mapUser(user: UserResponse): CurrentUser {
    const branch =
        user.branch === 0
            ? Branch.Toronto
            : user.branch === 1
            ? Branch.Vancouver
            : Branch.Coquitlam
    return {
        ...user,
        isAdmin: user.is_admin === 1,
        branch,
    }
}

export type LoginParam = {
    login: string
    password: string
}
export async function login(data: LoginParam) {
    const response = await request({
        path: 'login',
        method: 'POST',
        data,
    })
    const user = mapUser(response)
    setCurrentUser(user)
    history.push(routes.daily())
}

export async function logout() {
    await request({
        path: 'logout',
        method: 'DELETE',
    })
    setCurrentUser(null)
    history.push(routes.login())
}
