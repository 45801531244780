import request from 'Util/request'

export async function loadUsers() {
    return await request({
        method: 'GET',
        path: 'admin',
    })
}

export async function deleteUser(id: number) {
    return await request({
        method: 'DELETE',
        path: 'delete_user',
        params: {
            id,
        },
    })
}

export type UserBody = {
    id?: number
    login: string
    name: string
    password: string
}
export async function createUser(id: number, data: UserBody) {
    if (id > 0) {
        data.id = id
    }
    return await request({
        method: 'POST',
        path: 'create_user',
        data,
    })
}
