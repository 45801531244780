import { createContext, Dispatch } from 'react'
import { setItem, useLocalStorage } from 'Util/localStorage'

export enum Branch {
    Toronto = 'Toronto',
    Vancouver = 'Vancouver',
    Coquitlam = 'Coquitlam',
}
export type CurrentUser = {
    token: string
    isAdmin: boolean
    id: number
    login: string
    name: string
    branch: Branch
}

const validateUser = (user: CurrentUser) => {
    if (
        'id' in user &&
        'token' in user &&
        'name' in user &&
        'login' in user &&
        'isAdmin' in user &&
        'branch' in user
    )
        return user

    return null
}

const storageKey = 'user'
let currentUser: CurrentUser | undefined

export const setCurrentUser = (user: CurrentUser | null) => {
    currentUser = user as CurrentUser
    setItem(storageKey, user ? JSON.stringify(user) : null)
}

export const getCurrentUser = () => {
    if (!currentUser) {
        const json = localStorage.getItem(storageKey)
        currentUser = JSON.parse(json as string) as CurrentUser
    }
    return currentUser
}

export const useCurrentUser = () => {
    const [json] = useLocalStorage(storageKey)

    if (!currentUser)
        currentUser = (json
            ? validateUser(JSON.parse(json) as CurrentUser)
            : null) as unknown as CurrentUser

    return [currentUser, setCurrentUser] as const
}

export const getUserToken = () => getCurrentUser()?.token

export enum ActionType {
    UsersLoaded = 'usersLoaded',
    UserLoaded = 'userLoaded',
    UserAdded = 'userAdded',
    UserCleared = 'userCleared',
}

export type User = {
    id: number
    login: string
    name: string
    password?: string
}
export const emptyUser: User = {
    id: 0,
    login: '',
    name: '',
    password: '',
}

export type UserState = {
    users: User[]
    user: User | undefined
}

export type UserAction =
    | {
          type: ActionType.UsersLoaded
          users: User[]
      }
    | {
          type: ActionType.UserAdded
          user: User
      }
    | {
          type: ActionType.UserLoaded
          user: User | undefined
      }
    | {
          type: ActionType.UserCleared
      }

export type UserContextType = {
    userState: UserState
    dispatch: Dispatch<UserAction>
}

export const initialState: UserState = {
    users: [],
    user: undefined,
}

export const userReducer = (
    state: UserState,
    action: UserAction,
): UserState => {
    switch (action.type) {
        case ActionType.UsersLoaded:
            return {
                ...state,
                users: action.users,
            }
        case ActionType.UserAdded:
            // eslint-disable-next-line no-case-declarations
            let users = state.users
            if (users.filter((u) => u.id === action.user.id).length > 0) {
                users = users.map((u) =>
                    u.id === action.user.id ? action.user : u,
                )
            } else {
                users = users.concat([action.user])
            }
            return {
                ...state,
                users,
            }
        case ActionType.UserLoaded:
            return {
                ...state,
                user: action.user,
            }
        case ActionType.UserCleared:
            return {
                ...state,
                user: undefined,
            }
        default:
            return initialState
    }
}

export const UserContext = createContext<UserContextType>(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    undefined,
)
