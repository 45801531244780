import React, { useContext } from 'react'
import routes from 'routes'
import { NavLink, useParams } from 'react-router-dom'
import { ClassContext } from 'Store/Class'
import history from 'Util/history'
import {
    useLoadClass,
    useLoadStudentsInClass,
    useLoadStudentsInClassCount,
    useUpdateStudentData,
} from 'Action/class'
import Loader from 'Shared/Loader'
import cn from 'classnames'
import { pageValues } from 'Util/page'
import { useForm } from 'react-hook-form'
import ClassStudentRow from './Form/ClassStudentRow'
import Modal from 'Shared/Modal'
import Management from 'Management'
import useToggle from 'Util/useToggle'

type Param = {
    id?: string | undefined
}

export default function Detail() {
    const { id } = useParams<Param>()
    const page =
        parseInt(
            new URLSearchParams(window.location.search).get('page') ?? '0',
        ) ?? 0
    const classId = parseInt(id ?? '0')
    const {
        classState: {
            class: heraldClass,
            studentsInClass,
            studentsInClassCount: pageCount,
        },
    } = useContext(ClassContext)
    const [isLoading, setLoading] = React.useState(false)

    const loadClass = useLoadClass()
    const loadStudents = useLoadStudentsInClass()
    const loadStudentsCount = useLoadStudentsInClassCount()

    const updateStudent = useUpdateStudentData()
    const [openModal, toggleModal] = useToggle(false)

    const form = useForm()

    React.useEffect(() => {
        if (classId && heraldClass.id === 0) {
            setLoading(true)
            loadClass(classId).then(() => {
                setLoading(false)
            })
        }
    }, [classId])
    React.useEffect(() => {
        if (!studentsInClass[page]) {
            loadStudents(classId, page).then()
        }
    }, [page, studentsInClass[page]])
    React.useEffect(() => {
        if (pageCount === 0) {
            loadStudentsCount(classId).then()
        }
    }, [pageCount])

    const onBackClick = () => {
        if (history.length > 2) {
            history.goBack()
        } else {
            history.push(routes.class())
        }
    }

    const onSubmit = async (values: { start: string; end: string }) => {
        await updateStudent(values.start, values.end)
    }

    const { pageStart, pageEnd, prevExits, nextExists, pageRange } = pageValues(
        page,
        pageCount,
    )

    return (
        <>
            {openModal && (
                <Modal onClose={toggleModal}>
                    <Management isClass={true} onClose={toggleModal} />
                </Modal>
            )}
            <div className="w-full flex-1 mx-auto pt-9 px-2 sm:px-7 flex flex-col">
                {isLoading && (
                    <Loader className="w-8 h-8 mt-2 mx-auto" color="#06ACEE" />
                )}
                <div className={cn('flex flex-row', isLoading && 'hidden')}>
                    <span className="font-bold text-3xl ml-1.5">
                        {heraldClass.name}
                    </span>
                    <button
                        className="text-base bg-gray-e4 h-7 w-20 text-center text-black border border-gray-97 rounded ml-auto my-auto"
                        onClick={onBackClick}
                    >
                        Go Back
                    </button>
                    <button
                        className="text-base bg-red-tint h-7 w-20 text-center text-white rounded ml-2 my-auto"
                        onClick={toggleModal}
                    >
                        Add
                    </button>
                </div>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <table
                        className={cn(
                            'w-full table-fixed sm:table-auto mt-3.5 text-center border-separate',
                            isLoading && 'hidden',
                        )}
                    >
                        <thead>
                            <tr className="bg-blue-thead">
                                <th>No.</th>
                                <th className="hidden sm:table-cell">
                                    Student
                                </th>
                                <th className="sm:hidden">Name</th>
                                <th className="hidden sm:table-cell">
                                    Telephone
                                </th>
                                <th className="hidden sm:table-cell">Email</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th className="hidden sm:table-cell">
                                    Management
                                </th>
                                <th className="sm:hidden">Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            {studentsInClass[page] &&
                                studentsInClass[page].map((student, i) => (
                                    <ClassStudentRow
                                        form={form}
                                        student={student}
                                        className={
                                            i % 2 === 0
                                                ? 'bg-white'
                                                : 'bg-blue-tbody'
                                        }
                                        key={i}
                                    />
                                ))}
                        </tbody>
                    </table>
                </form>
                <div className="mx-auto my-5 flex flex-row flex-1 space-x-2">
                    {prevExits && (
                        <NavLink
                            to={`${routes.classDetail(classId)}?page=${
                                pageStart - 2
                            }`}
                            className="text-base text-black"
                        >
                            &lt;
                        </NavLink>
                    )}
                    {pageRange.map((r) => (
                        <NavLink
                            key={r}
                            to={`${routes.classDetail(classId)}?page=${r - 1}`}
                            className={cn(
                                'text-base',
                                r - 1 === page
                                    ? 'text-blue-primary'
                                    : 'text-black',
                            )}
                        >
                            {r}
                        </NavLink>
                    ))}
                    {nextExists && (
                        <NavLink
                            to={`${routes.classDetail(
                                classId,
                            )}?page=${pageEnd}`}
                            className="text-base text-black"
                        >
                            &gt;
                        </NavLink>
                    )}
                </div>
            </div>
        </>
    )
}
