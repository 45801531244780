import { createContext, Dispatch } from 'react'
import { replaceExistence } from 'Util/existence'
import { Student } from './Student'

export enum ActionType {
    ClassAdded = 'ClassAdded',
    ClassesLoaded = 'ClassesLoaded',
    ClassLoaded = 'ClassLoaded',
    ClassPagesLoaded = 'ClassPagesLoaded',
    ClassesClear = 'ClassesClear',
    ClassModifyingSet = 'ClassModifyingSet',
    ClassStudentsInLoaded = 'ClassStudentsInLoaded',
    ClassStudentsInCountLoaded = 'ClassStudentsInCountLoaded',
    ClassStudentsNotInLoaded = 'ClassStudentsNotInLoaded',
    ClassStudentsNotInCountLoaded = 'ClassStudentsNotInCountLoaded',
    ClassStudentModifyingSet = 'ClassStudentModifyingSet',
    ClassStudentAdded = 'ClassStudentAdded',
    ClassStudentsNotInClear = 'ClassStudentsNotInClear',
}

export type HeraldClass = {
    id: number
    name: string
    date: string
}
export type ClassStudent = {
    start: Date
    end: Date
    student: Student
}

export const emptyClass: HeraldClass = {
    id: 0,
    name: '',
    date: '',
}

export type ClassState = {
    classes: { [key: number]: HeraldClass[] }
    class: HeraldClass
    pageCount: number
    studentsInClass: { [key: number]: ClassStudent[] }
    studentsInClassCount: number
    studentsNotInClass: { [key: number]: Student[] }
    studentsNotInClassCount: number
    modifyingStudent: ClassStudent | undefined
    modifyingClass: HeraldClass | undefined
}

export type ClassAction =
    | {
          type: ActionType.ClassPagesLoaded
          count: number
      }
    | {
          type: ActionType.ClassesLoaded
          page: number
          classes: HeraldClass[]
      }
    | {
          type: ActionType.ClassLoaded
          class: HeraldClass
      }
    | {
          type: ActionType.ClassAdded
          class: HeraldClass
      }
    | {
          type: ActionType.ClassesClear
      }
    | {
          type: ActionType.ClassModifyingSet
          class: HeraldClass | undefined
      }
    | {
          type: ActionType.ClassStudentsInLoaded
          students: ClassStudent[]
          page: number
      }
    | {
          type: ActionType.ClassStudentsNotInLoaded
          students: Student[]
          page: number
      }
    | {
          type: ActionType.ClassStudentsInCountLoaded
          count: number
      }
    | {
          type: ActionType.ClassStudentsNotInCountLoaded
          count: number
      }
    | {
          type: ActionType.ClassStudentModifyingSet
          student: ClassStudent | undefined
      }
    | {
          type: ActionType.ClassStudentAdded
          student: ClassStudent
      }
    | {
          type: ActionType.ClassStudentsNotInClear
      }

export type ClassContextType = {
    classState: ClassState
    dispatch: Dispatch<ClassAction>
}

export const initialState: ClassState = {
    classes: {},
    class: emptyClass,
    modifyingClass: undefined,
    modifyingStudent: undefined,
    pageCount: 0,
    studentsInClass: {},
    studentsInClassCount: 0,
    studentsNotInClass: {},
    studentsNotInClassCount: 0,
}

export const classReducer = (
    state: ClassState,
    action: ClassAction,
): ClassState => {
    switch (action.type) {
        case ActionType.ClassPagesLoaded:
            return {
                ...state,
                pageCount: action.count,
            }
        case ActionType.ClassesLoaded:
            // eslint-disable-next-line no-case-declarations
            const classes = state.classes
            classes[action.page] = action.classes
            return {
                ...state,
                classes,
            }
        case ActionType.ClassLoaded:
            return {
                ...state,
                class: action.class,
                studentsInClass: [],
                studentsNotInClass: [],
                studentsInClassCount: 0,
                studentsNotInClassCount: 0,
            }
        case ActionType.ClassAdded:
            return {
                ...state,
                classes: replaceExistence(state.classes, action.class),
            }
        case ActionType.ClassesClear:
            return {
                ...state,
                classes: {},
            }
        case ActionType.ClassModifyingSet:
            return {
                ...state,
                modifyingClass: action.class,
            }
        case ActionType.ClassStudentsInLoaded:
            // eslint-disable-next-line no-case-declarations
            const studentsInClass = state.studentsInClass
            studentsInClass[action.page] = action.students
            return {
                ...state,
                studentsInClass,
            }
        case ActionType.ClassStudentsNotInLoaded:
            // eslint-disable-next-line no-case-declarations
            const studentsNotInClass = state.studentsNotInClass
            studentsNotInClass[action.page] = action.students
            return {
                ...state,
                studentsNotInClass,
            }
        case ActionType.ClassStudentsInCountLoaded:
            return {
                ...state,
                studentsInClassCount: action.count,
            }
        case ActionType.ClassStudentsNotInCountLoaded:
            return {
                ...state,
                studentsNotInClassCount: action.count,
            }
        case ActionType.ClassStudentModifyingSet:
            return {
                ...state,
                modifyingStudent: action.student,
            }
        case ActionType.ClassStudentAdded:
            return {
                ...state,
                studentsInClass: replaceExistence(
                    state.studentsInClass,
                    action.student,
                ),
            }
        case ActionType.ClassStudentsNotInClear:
            return {
                ...state,
                studentsNotInClass: {},
            }
        default:
            return initialState
    }
}

export const ClassContext = createContext<ClassContextType>(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    undefined,
)
