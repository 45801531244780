import React, { useContext, useEffect } from 'react'
import { emptyUser, User, UserContext } from 'Store/User'
import { useCurrentUser } from 'Store/User'
import {
    useCreateUser,
    useDeleteUser,
    useLoadUsers,
    useSetUser,
} from 'Action/admin'
import { useForm } from 'react-hook-form'
import Row from './Form/Row'
import assert from 'assert'

export default function Admin() {
    const { userState } = useContext(UserContext)
    const [currentUser] = useCurrentUser()
    const loadUsers = useLoadUsers()
    const setUser = useSetUser()
    const deleteUser = useDeleteUser()
    const createUser = useCreateUser()
    const form = useForm()

    useEffect(() => {
        if (userState.users.length === 0) {
            loadUsers().then()
        }
    }, [userState.users])
    const onCreateClick = () => {
        setUser(emptyUser).then()
        form.setValue('login', emptyUser.login)
        form.setValue('name', emptyUser.name)
        form.setValue('password', emptyUser.password)
    }
    const onSubmit = async (values: {
        login: string
        name: string
        password: string
    }) => {
        console.log(values)
        assert(userState.user !== undefined)
        await createUser(userState.user.id, values)
        await setUser(undefined)
    }
    const onModifyUser = async (user: User) => {
        await setUser(user)
        form.setValue('login', user.login)
        form.setValue('name', user.name)
    }
    const onDeleteUser = async (user: User) => {
        if (
            window.confirm(
                '한번 삭제한 자료는 복구할 방법이 없습니다.\n\n정말 삭제하시겠습니까?',
            )
        ) {
            deleteUser(user).then()
        }
    }
    const isSmallDisplay = window.matchMedia('(max-width: 768px)').matches

    return (
        <div className="w-full flow-1 mx-auto pt-9 px-2 sm:px-7 pb-4">
            <div className="flex flex-row">
                <span className="font-bold text-3xl ml-1.5">Admin</span>
                <button
                    className="text-base bg-red-tint h-7 w-20 text-center text-white rounded ml-auto my-auto"
                    onClick={onCreateClick}
                >
                    Create
                </button>
            </div>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <table className="w-full mt-3.5 text-center border-separate">
                    <thead>
                        <tr className="bg-blue-thead">
                            <th>No.</th>
                            <th>ID</th>
                            <th>User Name</th>
                            <th className="hidden md:table-cell">Password</th>
                            <th className="md:hidden">Pass</th>
                            <th className="hidden md:table-cell">Management</th>
                            <th className="md:hidden">Manage</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userState.users &&
                            userState.users.map((user, i) => (
                                <Row
                                    form={form}
                                    user={user}
                                    className={
                                        i % 2 === 0
                                            ? 'bg-white'
                                            : 'bg-blue-tbody'
                                    }
                                    shouldShowForm={
                                        !!(
                                            !isSmallDisplay &&
                                            userState.user &&
                                            userState.user.id === user.id
                                        )
                                    }
                                    hideDelete={currentUser.id === user.id}
                                    onModifyClick={onModifyUser}
                                    onDeleteClick={onDeleteUser}
                                    key={i}
                                />
                            ))}
                        {!isSmallDisplay &&
                            userState.user &&
                            userState.user.id === 0 && (
                                <Row
                                    form={form}
                                    user={userState.user}
                                    className={
                                        userState.users.length % 2 === 0
                                            ? 'bg-white'
                                            : 'bg-blue-tbody'
                                    }
                                    shouldShowForm={true}
                                    onModifyClick={onModifyUser}
                                    onDeleteClick={onDeleteUser}
                                />
                            )}
                    </tbody>
                </table>
                {isSmallDisplay && userState.user && (
                    <div className="flex flex-col w-full py-4 bg-blue-tbody">
                        <input
                            {...form.register('login')}
                            placeholder="ID"
                            className="h-10"
                        />
                        <input
                            {...form.register('name')}
                            placeholder="Name"
                            className="h-10 mt-3"
                        />
                        <input
                            {...form.register('password')}
                            type="password"
                            className="h-10 mt-3"
                            placeholder="Password"
                        />
                        <button
                            type="submit"
                            className="text-base bg-red-tint h-7 w-20 text-center text-white rounded mx-auto mt-5"
                        >
                            Save
                        </button>
                    </div>
                )}
            </form>
        </div>
    )
}
